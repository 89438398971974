import styled from 'styled-components';
import { FunctionComponent, useState } from 'react';
import { Location } from '@infarm/faas-middleware-types';
import { OrganizationalFarm, UpdatedFarm } from './types';
import { formatHubGrafanaLink, sortFarm, updateFarmsList } from './utils';
import { DsButton } from '@infarm/design-system-react';
import { FarmListHeader } from './FarmListHeader';
import { useQuery } from '@apollo/client';
import { GET_ALL_FARMS_FOR_LOCATION } from '../../lib/queries';
import { FarmRow } from './FarmRow';

const LocationRowContainer = styled.tr<LocationTableType>`
  align-items: center;
  display: grid;
  grid-template-rows: repeat(1, auto);
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  border: 1px solid #e7e7e7;
  padding: 4px 0;
  --color-mid: #767676;
  border-radius: 4px;
  background: ${(props: LocationTableType) => (props.loaded ? '#F5F5F5' : 'none')};
  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
`;
interface LocationTableType {
  loaded?: boolean;
}

const FarmCell = styled.td`
  position: relative;
  display: flex;
  align-items: center;
  padding: 12px 16px 8px 0;
`;

const DashboardLink = styled.a`
  display: block;
  height: 100%;
  margin-left: auto;
  line-height: 1;
`;

const FarmLocationButton = styled.button`
  background: none;
  width: 100%;
  border: 0;
  font-size: 16px;
  text-align: left;
  padding: 4px 12px;
  cursor: pointer;
`;

const LocationTable = styled.table<LocationTableType>`
  width: 100%;
  margin-bottom: ${(props: LocationTableType) => (props.loaded ? '48px' : '0px')};
`;

const LoadingCell = styled.td`
  padding: 54px 0;
`;

const SubText = styled.p`
  font-size: 12px;
  color: #666666;
  letter-spacing: 0.02em;
  line-height: 24px;
`;

export const LocationRow: FunctionComponent<{
  location: Location;
  openSerialLinkModal: Function;
  updatedFarmData?: UpdatedFarm;
}> = ({ location, openSerialLinkModal, updatedFarmData }) => {
  const [expanded, setExpanded] = useState(false);
  const toggleExpanded = () => {
    setExpanded(val => !val);
  };

  const { data, loading } = useQuery(GET_ALL_FARMS_FOR_LOCATION, {
    variables: {
      limit: 100,
      uuid: location.uuid
    },
    errorPolicy: 'all',
    notifyOnNetworkStatusChange: true, // required for `loading` to be updated when calling `fetchMore`
    skip: !expanded
  });

  let farmData = updateFarmsList(data?.location?.farms as OrganizationalFarm[], updatedFarmData);

  if (farmData) {
    farmData = [...farmData].sort(sortFarm);
  }

  return (
    <LocationTable loaded={!!data && !loading}>
      <tbody>
        <LocationRowContainer loaded={!!data && !loading}>
          <FarmCell>
            <FarmLocationButton
              data-cy={`LocationRow___expand___${location.name}`}
              onClick={() => toggleExpanded()}
            >
              <b>{location.name}</b>
              <SubText>{`${location?.numberOfOrganizationalFarmAcresForHub} farms`}</SubText>
            </FarmLocationButton>
            <DashboardLink target="_blank" href={formatHubGrafanaLink(location.name)}>
              <DsButton
                style={{ fontSize: '16px' }}
                icon="open_in_new"
                flat={true}
                label="View Hub Dashboard"
                data-cy={`LocationRow___hubLink___${location.name}`}
              />
            </DashboardLink>
          </FarmCell>
        </LocationRowContainer>
        {expanded && loading && (
          <tr>
            <LoadingCell>Loading...</LoadingCell>
          </tr>
        )}
        {expanded && data && !loading && <FarmListHeader hiddenColumns={['location']} />}
        {expanded &&
          data &&
          farmData &&
          farmData.map(farm => (
            <FarmRow
              key={`farm-${farm.uuid}`}
              hiddenColumns={['location']}
              farm={farm}
              openSerialLinkModal={openSerialLinkModal}
            />
          ))}
      </tbody>
    </LocationTable>
  );
};
