import { FunctionComponent } from 'react';
import { DynamicRecipeAttribute } from '../../../pages/DynamicRecipes';
import { EditableCellStyle, Container, InputStyle } from './CellStyles';
import { IrrigationSchedule } from '../DynamicRecipeRow';

type EditableCellLocation = {
  row: number;
  column: DynamicRecipeAttribute;
};

export type IrrigationsEditableCellChanged = {
  value?: IrrigationSchedule | null;
  location: EditableCellLocation;
};

export type CellParameters = {
  irrigationsPerLightsOn?: number | string;
  irrigateEveryNDays?: number;
};

export const IrrigationsEditableCell: FunctionComponent<{
  parameters: CellParameters;
  edge?: boolean;
  isFinalRow: boolean;
}> = ({ parameters, edge, isFinalRow }) => {
  const { irrigationsPerLightsOn, irrigateEveryNDays } = parameters;

  return (
    <EditableCellStyle edge={edge}>
      <Container>
        {!isFinalRow && (
          <InputStyle
            type="number"
            value={irrigationsPerLightsOn}
            disabled={true}
            placeholder="-"
          />
        )}
        {isFinalRow && irrigateEveryNDays && (
          <InputStyle value={`Every ${irrigateEveryNDays} days`} disabled={true} />
        )}
        {isFinalRow && irrigationsPerLightsOn && (
          <InputStyle type="number" value={irrigationsPerLightsOn} disabled={true} />
        )}
      </Container>
    </EditableCellStyle>
  );
};
