import { DsButton, DsModal } from '@infarm/design-system-react/dist/components';
import {
  FarmCommandPart,
  FarmStateResponse,
  FarmStatus,
  UserAction
} from '@infarm/faas-middleware-types';
import { FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import { BypassCommandAction, ConfirmModalSteps, IrsState } from './types';
import {
  getCardCopy,
  getIrsStatusFromState,
  getIrsCommandFromState,
  getIrsState,
  prepCommandMutationVars,
  lastUpdatedRecipeFormat
} from './utils';
import { IconTooltip } from './IconTooltip';
import { IrsBypassModal } from './IrsBypassModal';
import { IrsConfirmModal } from './IrsConfirmModal';
import { SEND_FARM_COMMAND } from '../../lib/queries';
import { useMutation } from '@apollo/client';

interface FarmControlProps {
  farmUuid: string;
  disabled: boolean;
  userActions: UserAction[];
  farmState: FarmStateResponse;
  reloadUserActions: () => void;
}

const baseSize = 16;

const ActiveControlIcon = styled.img`
  background-color: #a3fcb2;
  width: ${baseSize * 3}px;
  height: ${baseSize * 3}px;
  border-radius: 2rem;
`;

const InactiveControlIcon = styled.img`
  background-color: #ffc647;
  width: ${baseSize * 3}px;
  height: ${baseSize * 3}px;
  border-radius: 2rem;
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 1.25rem;
`;

const Copy = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.h4`
  margin: 0;
  font-size: ${baseSize}px;
  line-height: 1.6em;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const Footer = styled.div`
  margin-bottom: 0px;
  padding: 0.5rem 1.25rem 1.25rem 1.25rem;
`;

export const IrrigationSystem: FunctionComponent<FarmControlProps> = ({
  disabled,
  userActions,
  farmUuid,
  farmState,
  reloadUserActions
}) => {
  const { button, icon, icon_inactive } = getCardCopy(
    FarmCommandPart.IrsBypass1,
    false,
    FarmStatus.Running
  );
  const [irsCommandModalOpen, setIrsCommandModalOpen] = useState(false);
  const [irsConfirmModalOpen, setIrsConfirmModalOpen] = useState(false);
  const [irsFinalModalOpen, setIrsFinalModalOpen] = useState(false);

  const [irsDeactivateModalOpen, setIrsDeactivateModalOpen] = useState(false);
  const [irsDeactivateFinalModalOpen, setIrsDeactivateFinalModalOpen] = useState(false);

  const [currentIrsState, setCurrentIrsState] = useState(IrsState.ACTIVE);
  const [currentIrsStation, setCurrentIrsStation] = useState(0);
  const [irsCommand, setIrsCommand] = useState<string>('');

  const [sendBypassCommand, { error: mutationError, reset: resetMutationError }] = useMutation(
    SEND_FARM_COMMAND
  );

  const openIrsCommandModal = (irsNumber: number) => {
    setIrsCommandModalOpen(true);
    setCurrentIrsStation(irsNumber);
  };
  const openIrsCommandFinalModal = () => {
    const mutationInput = prepCommandMutationVars(
      BypassCommandAction.ENABLE,
      irsCommand,
      currentIrsStation,
      farmUuid
    );
    sendBypassCommand({
      variables: mutationInput,
      onCompleted() {
        reloadUserActions();
      }
    });

    setIrsConfirmModalOpen(false);
    setIrsFinalModalOpen(true);
  };

  const openDeactivateCommandModal = (irsNumber: number, irsState: IrsState) => {
    setIrsDeactivateModalOpen(true);
    setCurrentIrsStation(irsNumber);
    setCurrentIrsState(irsState);
    setIrsCommand(getIrsCommandFromState(irsState));
  };

  const openIrsDecativateFinalModal = () => {
    const command = getIrsCommandFromState(currentIrsState);
    const mutationInput = prepCommandMutationVars(
      BypassCommandAction.DISABLE,
      command,
      currentIrsStation,
      farmUuid
    );
    sendBypassCommand({
      variables: mutationInput,
      onCompleted() {
        reloadUserActions();
      }
    });
    setIrsDeactivateModalOpen(false);
    setIrsDeactivateFinalModalOpen(true);
  };

  const closeIrsCommandModal = () => {
    setIrsCommandModalOpen(false);
    setIrsConfirmModalOpen(false);
    setIrsFinalModalOpen(false);
    setIrsDeactivateModalOpen(false);
    setIrsDeactivateFinalModalOpen(false);

    setCurrentIrsStation(0);
  };

  const confirmIrsBypass = (modalIrsCommand: string) => {
    setIrsCommand(modalIrsCommand);
    setIrsCommandModalOpen(false);
    setIrsConfirmModalOpen(true);
  };

  let irs1Status = '';
  let irs2Status = '';
  let irs1State = IrsState.ACTIVE;
  let irs2State = IrsState.ACTIVE;
  if (farmState) {
    irs1State = getIrsState(farmState, 1);
    irs2State = getIrsState(farmState, 2);
    irs1Status = getIrsStatusFromState(irs1State);
    irs2Status = getIrsStatusFromState(irs2State);
  }

  const userAction1 = userActions.find(action => action.section === FarmCommandPart.IrsBypass1);
  const userAction2 = userActions.find(action => action.section === FarmCommandPart.IrsBypass2);

  const historyText1 = lastUpdatedRecipeFormat(userAction1);
  const historyText2 = lastUpdatedRecipeFormat(userAction2);

  return (
    <>
      <Content>
        {irs2State === IrsState.ACTIVE ? (
          <ActiveControlIcon src={icon} />
        ) : (
          <InactiveControlIcon src={icon_inactive} />
        )}
        <Copy>
          <TitleContainer>
            <Title>IRS 2 - top</Title>
            {userAction2 && <IconTooltip text={historyText2} icon="history" />}
          </TitleContainer>
          <span
            style={{ color: irs2State === IrsState.ACTIVE ? '#00952B' : '#a35400' }}
            data-cy={`farm-control___status-irs2`}
          >
            {irs2Status}
          </span>
        </Copy>
      </Content>
      <Footer>
        <DsButton
          label={irs2State === IrsState.ACTIVE ? button : 'Disable Bypass'}
          onClick={() => {
            if (irs2State === IrsState.ACTIVE) {
              openIrsCommandModal(2);
            } else {
              openDeactivateCommandModal(2, irs2State);
            }
          }}
          disabled={disabled}
          primary={irs2State === IrsState.ACTIVE}
          data-cy={`farm-control___toggle-irs2`}
        ></DsButton>
      </Footer>
      <Content>
        {irs1State === IrsState.ACTIVE ? (
          <ActiveControlIcon src={icon} />
        ) : (
          <InactiveControlIcon src={icon_inactive} />
        )}
        <Copy>
          <TitleContainer>
            <Title>IRS 1 - bottom</Title>
            {userAction1 && <IconTooltip text={historyText1} icon="history" />}
          </TitleContainer>
          <span
            style={{ color: irs1State === IrsState.ACTIVE ? '#00952B' : '#a35400' }}
            data-cy={`farm-control___status-irs2`}
          >
            {irs1Status}
          </span>
        </Copy>
      </Content>
      <Footer>
        <DsButton
          label={irs1State === IrsState.ACTIVE ? button : 'Disable Bypass'}
          onClick={() => {
            if (irs1State === IrsState.ACTIVE) {
              openIrsCommandModal(1);
            } else {
              openDeactivateCommandModal(1, irs1State);
            }
          }}
          disabled={disabled}
          primary={irs1State === IrsState.ACTIVE}
          data-cy={`farm-control___toggle-irs1`}
        ></DsButton>
      </Footer>

      <DsModal hideCloseButton={true} width={650} open={irsCommandModalOpen}>
        <IrsBypassModal
          confirmBypass={confirmIrsBypass}
          closeModal={closeIrsCommandModal}
          irsStation={currentIrsStation}
        />
      </DsModal>
      <DsModal hideCloseButton={true} width={600} open={irsConfirmModalOpen}>
        <IrsConfirmModal
          step={ConfirmModalSteps.CONFIRM}
          confirmModal={openIrsCommandFinalModal}
          bypassType={irsCommand}
          closeModal={closeIrsCommandModal}
          irsStation={currentIrsStation}
        />
      </DsModal>
      <DsModal hideCloseButton={true} width={500} open={irsFinalModalOpen}>
        <IrsConfirmModal
          step={ConfirmModalSteps.POST_CONFIRM}
          bypassType={irsCommand}
          closeModal={closeIrsCommandModal}
          irsStation={currentIrsStation}
        />
      </DsModal>
      <DsModal hideCloseButton={true} width={600} open={irsDeactivateModalOpen}>
        <IrsConfirmModal
          step={ConfirmModalSteps.DISABLE}
          confirmModal={openIrsDecativateFinalModal}
          bypassType={irsCommand}
          closeModal={closeIrsCommandModal}
          irsStation={currentIrsStation}
        />
      </DsModal>
      <DsModal hideCloseButton={true} width={500} open={irsDeactivateFinalModalOpen}>
        <IrsConfirmModal
          step={ConfirmModalSteps.POST_DISABLE}
          bypassType={irsCommand}
          closeModal={closeIrsCommandModal}
          irsStation={currentIrsStation}
        />
      </DsModal>
      <DsModal
        hideCloseButton={true}
        width={500}
        open={Boolean(mutationError)}
        onClosed={() => resetMutationError()}
      >
        <h2>Your last command failed due to an error, please report this to support.</h2>
      </DsModal>
    </>
  );
};
