import { useMutation, useQuery } from '@apollo/client';
import { DsButton, DsPlaceholder, DsRow, DsSnackbar, DsSpacer } from '@infarm/design-system-react';
import { GET_CRAC_CONFIG, SET_CRAC_CONFIG } from '../../../lib/queries';
import { userCanEditRecipes } from '../../../lib/access-control';
import { CracAcreV2 } from '@infarm/faas-middleware-types';
import { useEffect, useState } from 'react';
import { RecipeField } from './RecipeField';
import { RecipeCard, RecipeModal } from './styled-components';

interface AcreCracRecipeProps {
  serialNumber: string;
  isOnline: boolean;
}

const pickValue = (values: CracAcreV2[], key: keyof CracAcreV2, unit: string = ''): string => {
  const pickedValues = values.map(config => config[key]);
  if (pickedValues.every(value => value === pickedValues[0])) {
    return `${pickedValues[0]}${unit}`;
  } else {
    return pickedValues.map(value => `${value}${unit}`).join(' / ');
  }
};

export const AcreCracRecipeCard = ({ serialNumber, isOnline }: AcreCracRecipeProps) => {
  const { data, loading, error } = useQuery(GET_CRAC_CONFIG, { variables: { serialNumber } });
  const cracValues = data?.physicalFarm?.config?.global?.crac;
  const userCanEditRecipesValue = userCanEditRecipes();

  const [editFormVisible, showEditForm] = useState(false);
  const [success, setSuccess] = useState(false);
  const [isDirty, setDirty] = useState(false);
  const [temperature, setTemperature] = useState<number | null>(null);
  const [humidity, setHumidity] = useState<number | null>(null);
  const [airflow, setAirflow] = useState<number | null>(null);

  const [setCracConfig, mutationResult] = useMutation(SET_CRAC_CONFIG, {
    refetchQueries: [GET_CRAC_CONFIG]
  });

  useEffect(() => {
    if (mutationResult.called && mutationResult.data) {
      showEditForm(false);
      setSuccess(true);
    }
  }, [mutationResult.called, mutationResult.data]);

  const submitForm = () => {
    mutationResult.reset();
    setCracConfig({
      variables: {
        input: { serialNumber, crac: { temperature, humidity, airflowVolume: airflow } }
      }
    });
  };

  useEffect(() => {
    const cracValue = cracValues?.[0];
    if (!cracValue) return;
    setTemperature(cracValue.temperature);
    setHumidity(cracValue.humidity);
    setAirflow(cracValue.airflowVolume);
    setDirty(false);
    if (editFormVisible) {
      setSuccess(false);
    }
  }, [editFormVisible, cracValues]);

  useEffect(() => {
    const cracValue = cracValues?.[0];
    if (!cracValue || temperature === null || humidity === null || airflow === null) {
      setDirty(false);
      return;
    }
    if (
      temperature !== cracValue.temperature ||
      humidity !== cracValue.humidity ||
      airflow !== cracValue.airflowVolume
    ) {
      setDirty(true);
    }
  }, [cracValues, temperature, humidity, airflow]);

  return (
    <RecipeCard>
      <h3>Airflow Recipe</h3>
      {error ? (
        <>
          <p>Error loading airflow recipe:</p>
          <p>{error.message}</p>
        </>
      ) : loading ? (
        <DsPlaceholder></DsPlaceholder>
      ) : (
        <>
          <table>
            <thead>
              <tr>
                <td>Type</td>
                <td>Set point</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th data-title="Type">Temperature</th>
                <td data-title="Set point">{pickValue(cracValues, 'temperature', ' °C')}</td>
              </tr>
              <tr>
                <th data-title="Type">Humidity</th>
                <td data-title="Set point">{pickValue(cracValues, 'humidity', '%')}</td>
              </tr>
              <tr>
                <th data-title="Type">Airflow volume</th>
                <td data-title="Set point">{pickValue(cracValues, 'airflowVolume', ' m³/h')}</td>
              </tr>
            </tbody>
          </table>
          <DsSpacer></DsSpacer>
          {userCanEditRecipesValue && (
            <>
              <footer>
                <DsButton
                  data-cy={`recipe-card___editCracRecipe`}
                  label="Edit"
                  onClick={() => showEditForm(true)}
                />
              </footer>
              <RecipeModal
                heading="Edit Airflow Recipe"
                width={600}
                open={editFormVisible}
                hideCloseButton
                onClosed={() => showEditForm(false)}
              >
                <RecipeField
                  name="Temperature set point"
                  unit="°C"
                  min={15}
                  max={30}
                  step={0.1}
                  value={temperature}
                  onValue={setTemperature}
                ></RecipeField>
                <RecipeField
                  name="Humidity set point"
                  unit="%"
                  min={0}
                  max={100}
                  step={0.1}
                  value={humidity}
                  onValue={setHumidity}
                ></RecipeField>
                <RecipeField
                  name="Airflow volume set point"
                  unit="m³/h"
                  warning={
                    'Always make sure that airflow controllers for all light stations are open\nOR that the sum of the station airflow equals the below (CRAC) set point.'
                  }
                  min={7000}
                  max={22000}
                  value={airflow}
                  onValue={setAirflow}
                ></RecipeField>
                <footer>
                  <DsRow>
                    <DsSpacer></DsSpacer>
                    <DsButton stretch label="Cancel" onClick={() => showEditForm(false)}></DsButton>
                    <DsButton
                      stretch
                      primary
                      label="Apply"
                      disabled={!isDirty || mutationResult.loading}
                      onClick={submitForm}
                    ></DsButton>
                  </DsRow>
                </footer>
                {!isOnline && (
                  <p className="offline">
                    <strong>Farm {serialNumber} is offline:</strong> All changes will be applied
                    when the farm is back online
                  </p>
                )}
              </RecipeModal>
              {mutationResult.error && (
                <DsSnackbar
                  labelText={mutationResult.error.message}
                  open="true"
                  stacked
                ></DsSnackbar>
              )}
              {success && (
                <DsSnackbar
                  labelText="Airflow recipe has been updated successfully."
                  open="true"
                  stacked
                ></DsSnackbar>
              )}
            </>
          )}
        </>
      )}
    </RecipeCard>
  );
};
