import { DsSwitch } from '@infarm/design-system-react';
import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { RecipeHeaderCaption } from '../../pages/DynamicRecipes';

interface HeaderElementData {
  title: RecipeHeaderCaption;
  subtitle?: string;
  edge?: boolean;
  halfSize?: boolean;
  colSpan?: number;
}

const titleGray = '#626262';
export const mildGray = '#f5f5f5';
export const tableBorderColor = '#bdbdbd';

const HeaderRow = styled.tr`
  background-color: ${mildGray};
  &:not(:last-child) {
    border-bottom: 1px solid ${tableBorderColor};
  }
`;
const HeaderCell = styled.td`
  padding: 0.5rem;
  width: ${(props: HeaderElementData) => (props.halfSize ? '4rem' : '8rem')};
  border-right: ${(props: HeaderElementData) =>
    props.edge ? `1px solid ${tableBorderColor}` : 'none'};
`;
const HeaderCellTitle = styled.h4`
  color: ${titleGray};
  text-transform: uppercase;
  font-size: 0.75rem;
  line-height: 1;
  font-weight: 500;
`;

const HeaderCellSubTitle = styled.span`
  color: ${titleGray};
  font-size: 0.75rem;
  line-height: 1;
`;

const SwitchWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  > label {
    color: ${titleGray};
    font-size: 0.75rem;
  }
`;

export const DynamicRecipeTableHeader: FunctionComponent<{
  nightlyIrrigationsOn?: boolean;
}> = ({ nightlyIrrigationsOn }) => {
  const tableColumns: HeaderElementData[] = [
    {
      title: RecipeHeaderCaption.Day,
      edge: true
    },
    {
      title: RecipeHeaderCaption.LightStage2,
      subtitle: 'S-200 (%)'
    },
    {
      title: RecipeHeaderCaption.LightStage3,
      subtitle: 'S-300 (%)',
      edge: true
    },
    {
      title: RecipeHeaderCaption.IrrigationDay,
      subtitle: 'per day'
    },
    {
      title: RecipeHeaderCaption.SoakingTimeDay,
      subtitle: '(sec)',
      edge: true
    },
    {
      title: RecipeHeaderCaption.IrrigationNight,
      subtitle: 'per night'
    },
    {
      title: RecipeHeaderCaption.SoakingTimeNight,
      subtitle: '(sec)',
      edge: true
    }
  ];

  const tableMetaColumns: HeaderElementData[] = [
    {
      title: RecipeHeaderCaption.RecipeSettings,
      edge: true,
      colSpan: 1
    },
    {
      title: RecipeHeaderCaption.LightRecipe,
      edge: true,
      colSpan: 2
    },
    {
      title: RecipeHeaderCaption.IrrigationSchedule,
      colSpan: 5
    }
  ];

  return (
    <thead>
      <HeaderRow>
        {tableMetaColumns.map(column => (
          <HeaderCell
            edge={column.edge}
            key={column.title}
            title={column.title}
            colSpan={column.colSpan}
          >
            {column.title !== RecipeHeaderCaption.NightIrrigationsTogggle && (
              <HeaderCellTitle>{column.title}</HeaderCellTitle>
            )}
            {column.title === RecipeHeaderCaption.NightIrrigationsTogggle && (
              <SwitchWrapper>
                <label>{RecipeHeaderCaption.NightIrrigationsTogggle}</label>
                <DsSwitch disabled={true} size={20} value={nightlyIrrigationsOn} />
              </SwitchWrapper>
            )}
          </HeaderCell>
        ))}
      </HeaderRow>
      <HeaderRow>
        {tableColumns.map((column, index) => (
          <HeaderCell
            edge={column.edge}
            halfSize={column.halfSize}
            key={index}
            title={column.title}
          >
            <HeaderCellTitle>{column.title}</HeaderCellTitle>
            {column.subtitle ? <HeaderCellSubTitle>{column.subtitle}</HeaderCellSubTitle> : null}
          </HeaderCell>
        ))}
      </HeaderRow>
    </thead>
  );
};
