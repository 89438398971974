import styled from 'styled-components';
import { UpdatedFarm, OrganizationalFarm } from './types';
import { FarmModel, Location } from '@infarm/faas-middleware-types';

const env = (globalThis as any).env || {};

export const Link = styled.a`
  font-weight: 500;
  display: block;
`;

const LocationName = styled.span`
  line-height: 1.3;
`;

export const makeKompostFarmURN = (uuid: string): string => {
  return `urn:kompost:farm:${uuid}`;
};

export const updateFarmsList = (
  farmData: OrganizationalFarm[],
  updatedFarm: UpdatedFarm | undefined
): OrganizationalFarm[] => {
  if (!updatedFarm) {
    return farmData;
  }
  return farmData.map(farm => {
    let serialNumber = farm.serialNumber;
    if (makeKompostFarmURN(farm.uuid) === updatedFarm.organizationalFarmUrn) {
      serialNumber = updatedFarm.serialNumber;
    } else if (farm.serialNumber === updatedFarm.serialNumber) {
      serialNumber = undefined;
    }
    return { ...farm, serialNumber };
  });
};

export const formatLocationName = (farm: OrganizationalFarm) => {
  const { location, operationalFarm } = farm;
  const { uuid, name } = location;
  let locationName = location ? (
    <LocationName>{name}</LocationName>
  ) : (
    <div className="no-location">no assigned location</div>
  );

  if (operationalFarm?.model === FarmModel.Acre && location && uuid) {
    locationName = (
      <Link data-cy={`FarmRow_Location___${uuid}`} href={`../locations/${uuid}`}>
        {name}
      </Link>
    );
  }
  return locationName;
};
export const formatFarmName = (farm: OrganizationalFarm) => {
  const { name, serialNumber, location } = farm;
  let farmName = name ? <span title={name}>{name}</span> : <span>—</span>;
  if (name && location && serialNumber) {
    farmName = (
      <Link
        data-cy={`FarmRow_Farm___${serialNumber}`}
        title={name}
        href={`${(globalThis as any).env.FARMBOARD_ROOT}farms/${serialNumber}`}
      >
        {farmName}
      </Link>
    );
  }
  return farmName;
};

export const parseLocationResultsToOrganizationalFarms = (data: Location[] = []) => {
  return data.flatMap(({ organizationalFarms, name, uuid, locationGroup }: Location) =>
    organizationalFarms
      ?.filter(farm => !!farm?.isGen2)
      .flatMap(farm => {
        return {
          ...farm,
          location: { name, uuid, locationGroup }
        };
      })
  ) as OrganizationalFarm[];
};

export const formatHubGrafanaLink = (hubName: string | null | undefined): string => {
  if (!hubName) {
    return '';
  }
  return `${env.GRAFANA_ENDPOINT}/d/${hubName.replace(' ', '-')}`;
};

export const sortFarm = (farmA: OrganizationalFarm, farmB: OrganizationalFarm) => {
  const farmNumberRegex = /^Acre\s([0-9]+)/;
  const farmANumber = farmA.name?.match(farmNumberRegex);
  const farmBNumber = farmB.name?.match(farmNumberRegex);
  if (farmANumber && farmBNumber) {
    return parseInt(farmANumber[1]) - parseInt(farmBNumber[1]);
  }
  return 0;
};
