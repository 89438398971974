import styled from 'styled-components';
import { FunctionComponent } from 'react';
import { userCanAssignFarmId } from '../../lib/access-control';
import editIcon from '../../assets/icon-edit.svg';

interface SerialLink {
  missing: boolean;
}

const Button = styled.button<SerialLink>`
  background: none;
  border: 0;
  font-size: 16px;
  text-align: left;
  padding-left: 0px;
  width: 90%;
  height: 25px;
  cursor: pointer;
  text-decoration: none;
  color: ${props => (props.missing ? '#fa6400' : '#1A1A1A')};
  white-space: nowrap;
  width: 100%;
  display: flex;
`;

const EditIcon = styled.img`
  display: block;
  margin-left: 15px;
  width: 14px;
  height: 14px;
`;

const SerialNumberDisplay = styled.span`
  color: #1a1a1a;
`;

export const SerialNumber: FunctionComponent<{
  clickFn: Function;
  serialNumber?: string;
  farmUrn?: string;
  canAssignNewSerialNumber: boolean;
}> = ({ canAssignNewSerialNumber = false, serialNumber = null, farmUrn, clickFn }) => {
  const serialNumberDisplayText = serialNumber || 'Farm ID Missing';

  if (userCanAssignFarmId() && canAssignNewSerialNumber) {
    return (
      <Button
        data-cy={`FarmRow_SerialNumber___${farmUrn}`}
        missing={!serialNumber}
        onClick={() => clickFn(farmUrn)}
      >
        {serialNumberDisplayText}
        <EditIcon src={editIcon} />
      </Button>
    );
  }

  return <SerialNumberDisplay>{serialNumberDisplayText}</SerialNumberDisplay>;
};
