import styled, { css } from 'styled-components';
import { tableBorderColor } from '../DynamicRecipeTableHeader';

interface StyleProps {
  edge?: boolean;
  active?: boolean;
}

export const mildGrayShadow = '#e0e0e0';
const red = '#ff3b30';
const tooltipBackgroundRed = '#ffa594';

export const cell = css`
  border-top: 1px solid ${mildGrayShadow};
  text-align: left;
  padding: 0;
  min-height: 3.2rem;
  border-right: ${(props: StyleProps) =>
    props.edge ? `1px solid ${tableBorderColor}` : `1px solid ${mildGrayShadow}`};
`;

export const EditableCellStyle = styled.td`
  ${cell}
`;

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  .invalid&:not(:active) {
    border-bottom: 1px solid ${red};
  }
`;

export const Tooltip = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${red};
  background: ${tooltipBackgroundRed};
  margin-left: 9rem;
  margin-top: -0.25rem;
  position: absolute;
  z-index: 1000;
  padding: 0.25rem;
  border-radius: 0.25rem;
  strong {
    display: block;
  }
`;

export const Triangle = styled.div`
  height: 1rem;
  width: 1rem;
  background-color: ${tooltipBackgroundRed};
  position: relative;
  border-bottom: 1px solid ${red};
  border-left: 1px solid ${red};
  left: -0.75rem;
  top: 1rem;
  transform: rotate(45deg);
  z-index: -1;
`;

export const InputStyle = styled.input`
  background: none;
  border: 0;
  font-size: 16px;
  width: 100%;
  padding: 1rem;
  flex: 4;
  outline: none;
  &:focus-within :not(.invalid) {
    border-bottom: 1px solid black;
  }
  &.bold {
    font-weight: bold;
  }
`;

export const IconStyle = styled.img`
  flex: 1;
  height: 1rem;
  width: 1rem;
  align-self: center;
`;
