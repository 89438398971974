import { FunctionComponent } from 'react';
import { EditableCellStyle, Container, InputStyle } from './CellStyles';

export type CellParameters = {
  value?: string;
  bold?: boolean;
  dataCy?: string;
};

export const EditableCell: FunctionComponent<{
  parameters: CellParameters;
  edge?: boolean;
}> = ({ parameters, edge }) => {
  const { value, bold, dataCy } = parameters;
  return (
    <EditableCellStyle edge={edge}>
      <Container>
        <InputStyle
          value={value}
          data-cy={dataCy}
          disabled={true}
          placeholder="-"
          className={bold ? 'bold' : ''}
        />
      </Container>
    </EditableCellStyle>
  );
};
