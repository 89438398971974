import styled from 'styled-components';

import { FunctionComponent } from 'react';
import { GET_ALL_ACRE_LOCATIONS } from '../../lib/queries';
import { LocationRow } from './LocationRow';
import { useQuery } from '@apollo/client';
import { Location, LocationCategory } from '@infarm/faas-middleware-types';
import { UpdatedFarm } from './types';

const LoadingMessage = styled.div`
  width: 100%;
  padding: 32px 0;
  color: #666;
`;

interface FarmsListProps {
  openSerialLinkModal: Function;
  updatedFarmData?: UpdatedFarm;
  searchString?: string;
  isDisplayed: boolean;
}

const useFarmData = (isDisplayed: boolean, searchString: string | undefined) => {
  const queryVariables = {
    filter: { hasFarms: true, category: [LocationCategory.Production] }
  };

  const { data, loading, error } = useQuery(GET_ALL_ACRE_LOCATIONS, {
    variables: queryVariables,
    errorPolicy: 'all',
    skip: !isDisplayed
  });

  const locationDataRaw: Location[] = data?.allLocations;

  return { locationDataRaw, loading, error };
};

export const GroupedFarmList: FunctionComponent<FarmsListProps> = ({
  searchString,
  isDisplayed,
  openSerialLinkModal,
  updatedFarmData
}) => {
  const loadingResultsInfo: string = !!searchString
    ? 'Loading search results...'
    : 'Loading results...';
  const { locationDataRaw, loading, error } = useFarmData(isDisplayed, searchString);

  const locationData: Location[] = !searchString
    ? (locationDataRaw as Location[])
    : (locationDataRaw as Location[]).filter(location =>
        location.name.toLowerCase().includes(searchString.toLowerCase())
      );

  const loadErrorMessage = !!error
    ? 'Failed to load, please try again.'
    : 'No results found for your search. Please try again with another Location.';

  return (
    <div>
      {locationData &&
        !loading &&
        locationData.map(location => (
          <LocationRow
            updatedFarmData={updatedFarmData}
            openSerialLinkModal={openSerialLinkModal}
            key={`location-${location.uuid}`}
            location={location}
          />
        ))}
      {loading && <LoadingMessage>{loadingResultsInfo}</LoadingMessage>}
      {(!!error || (!loading && !!locationData && locationData.length === 0)) && (
        <LoadingMessage>{loadErrorMessage}</LoadingMessage>
      )}
    </div>
  );
};
