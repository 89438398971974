import { DsButton } from '@infarm/design-system-react/dist/components';
import { FarmCommand, FarmCommandPart, UserAction } from '@infarm/faas-middleware-types';
import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { CommandTriggerAttempt, ControlCopy } from './types';
import { lastUpdatedRecipeFormat } from './utils';
import { IconTooltip } from './IconTooltip';

interface FarmControlProps {
  disabled: boolean;
  farmCommandPart: FarmCommandPart;
  userAction: UserAction | undefined;
  isActivated: boolean;
  commandModalOpened: (pendingCommand: CommandTriggerAttempt) => void;
  copy: ControlCopy;
}
const warningColour = '#ffda87';

const baseSize = 16;

const ActiveControlIcon = styled.img`
  background-color: ${warningColour};
  width: ${baseSize * 3}px;
  height: ${baseSize * 3}px;
  border-radius: 2rem;
  padding: 0.5rem;
`;

const InactiveControlIcon = styled.img`
  background-color: #f2f2f2;
  width: ${baseSize * 3}px;
  height: ${baseSize * 3}px;
  border-radius: 2rem;
  padding: 0.5rem;
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 1.25rem;
`;

const Copy = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.h4`
  margin: 0;
  font-size: ${baseSize}px;
  line-height: 1.6em;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const Footer = styled.div`
  margin-bottom: 0px;
  padding: 0.5rem 1.25rem 1.25rem 1.25rem;
`;
const getFarmCommand = (farmCommandPart: FarmCommandPart, isActivated: boolean): FarmCommand => {
  switch (farmCommandPart) {
    case FarmCommandPart.Maintenance:
      return isActivated ? FarmCommand.DisableMaintenanceMode : FarmCommand.EnableMaintenanceMode;
    case FarmCommandPart.EcDosing:
    case FarmCommandPart.PhDosing:
      return isActivated ? FarmCommand.ActivateSubsystem : FarmCommand.BypassSubsystem;
    default:
      return FarmCommand.DisableMaintenanceMode;
  }
};

export const FarmControl: FunctionComponent<FarmControlProps> = ({
  disabled,
  farmCommandPart,
  userAction,
  isActivated,
  commandModalOpened,
  copy
}) => {
  const { title, status, button, icon } = copy;
  const cyTagId = farmCommandPart.toLowerCase().replace(/_/g, '-');
  const farmCommand = getFarmCommand(farmCommandPart, isActivated);
  const commandTriggerAttempt: CommandTriggerAttempt = {
    farmCommandPart,
    farmCommand
  };
  const historyText = lastUpdatedRecipeFormat(userAction);
  return (
    <>
      <Content>
        {isActivated ? <ActiveControlIcon src={icon} /> : <InactiveControlIcon src={icon} />}
        <Copy>
          <TitleContainer>
            <Title>{title}</Title>
            {userAction && <IconTooltip text={historyText} icon="history" />}
          </TitleContainer>
          <span
            style={{ color: isActivated ? '#a35400' : 'inherit' }}
            data-cy={`farm-control___status-${cyTagId}`}
          >
            {status}
          </span>
        </Copy>
      </Content>
      <Footer>
        <DsButton
          label={button}
          onClick={() => {
            commandModalOpened(commandTriggerAttempt);
          }}
          disabled={disabled}
          primary={isActivated}
          data-cy={`farm-control___toggle-${cyTagId}`}
        ></DsButton>
      </Footer>
    </>
  );
};
