import { FunctionComponent, useState } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { CustomElement } from './app/components/CustomElement';
import { DynamicRecipes } from './app/pages/DynamicRecipes';
import { Farms } from './app/pages/Farms';
import { Farm } from './app/pages/Farm';
import { ApolloProvider } from '@apollo/client';
import { getMiddlewareApolloClient } from './app/lib/apollo-client';
import styled from 'styled-components';

const middlewareApolloClient = getMiddlewareApolloClient();

const PageWrapper = styled.div`
  padding-top: 0px;
`;

export const Routing: FunctionComponent = () => {
  const [snackbarText, setSnackbarText] = useState<string>();

  return (
    <ApolloProvider client={middlewareApolloClient}>
      <BrowserRouter basename={(globalThis as any).env.FARMBOARD_ROOT}>
        <PageWrapper className="ds-layout-column">
          <Switch>
            <Redirect exact from="/farms" to="/farms/all" />
            <Route path="/farms/:tab(all|instore|inhub)" component={Farms} />
            <Route path="/farms/wcs/:serialNumber">
              <CustomElement element="fsd-wcs" />
            </Route>
            <Route path="/farms/:serialNumber/details">
              <CustomElement element="fsd-farm-details" />
            </Route>
            <Route path="/farms/:serialNumber/recipe/:recipeName">
              <DynamicRecipes />
            </Route>
            <Route path="/farms/:serialNumber">
              <Farm snackbarText={snackbarText} setSnackbarText={setSnackbarText} />
            </Route>
            {/* TODO hic sunt Saar */}
            <Route path="*">
              <p>Bad route</p>
            </Route>
          </Switch>
        </PageWrapper>
      </BrowserRouter>
    </ApolloProvider>
  );
};
