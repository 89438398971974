import { DsTextfield } from '@infarm/design-system-react';
import { useState } from 'react';
import styled from 'styled-components';

interface RecipeFieldProps {
  name: string;
  unit?: string;
  warning?: string;
  min: number;
  max: number;
  step?: number;
  value: number | null;
  onValue: (value: number | null) => void;
}

const RecipeContainer = styled.div`
  margin: 24px 0;

  label {
    font-weight: bold;
    color: #000;
  }

  p {
    margin: 0 0 8px 0 !important;
    font-size: 0.8em;
    white-space: pre-wrap;
  }

  p.invalid,
  p.warning {
    color: #e00028;
  }

  ds-textfield {
    width: 100%;
  }
`;

export const RecipeField = ({
  name,
  unit,
  min,
  max,
  value,
  step,
  onValue,
  warning
}: RecipeFieldProps) => {
  const [lastValue, setLastValue] = useState('');
  const onInput = (event: any) => {
    setLastValue(event.target.value);
    const value = parseFloat(event.target.value);
    if (isNaN(value)) {
      onValue(null);
    } else if (value >= min && value <= max) {
      onValue(value);
    } else {
      onValue(null);
    }
  };
  return (
    <RecipeContainer>
      <label>
        {name}
        {unit ? ` (${unit})` : ''}
      </label>
      <p className={value === null ? 'invalid' : ''}>
        {name} {value === null ? 'must' : 'can'} be between {min} - {max}
        {unit ? ` ${unit}` : ''}
      </p>
      {warning && <p className="warning">{warning}</p>}
      <DsTextfield
        type="number"
        min={min}
        max={max}
        step={`${step || 1}`}
        value={`${value ?? lastValue}`}
        required
        onInput={onInput}
      ></DsTextfield>
    </RecipeContainer>
  );
};
