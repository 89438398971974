import { DsButton } from '@infarm/design-system-react';
import { FunctionComponent, useState, ChangeEvent } from 'react';
import styled from 'styled-components';

interface IrsModalProps {
  irsStation: number;
  closeModal: () => void;
  confirmBypass: (bypassMode: string) => void;
}

type ModeSelection = {
  active: boolean;
};

const RadioInput = styled.input`
  position: absolute;
  right: 24px;
  top: 30px;
  width: 20px;
  height: 20px;
`;

const ModeSelectionLabel = styled.label<ModeSelection>`
  position: relative;
  display: block;
  width: 100%;
  border: ${props => (props.active ? '2px black solid' : '1px #666 solid')};
  margin: ${props => (props.active ? '8px 0' : '9px 1px')};
  padding: 16px;
  border-radius: 5px;
  cursor: pointer;
`;

const ModalFooter = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: flex-end;
  ds-button {
    margin-left: 16px;
  }
`;

const ModeGroup = styled.div`
  position: relative;
  box-sizing: border-box;
`;

const H4 = styled.h4`
  font-weight: normal;
`;

export const IrsBypassModal: FunctionComponent<IrsModalProps> = ({
  irsStation,
  closeModal,
  confirmBypass
}) => {
  const irsTitleText = irsStation === 1 ? '1 - bottom' : '2 - top';
  const [irsBypassMode, setIrsBypassMode] = useState<string | null>('');
  const handleChange = (e: ChangeEvent) => {
    setIrsBypassMode(e.target.getAttribute('value'));
  };

  return (
    <div>
      <h3>Enable bypass for Irrigation Station {irsTitleText}</h3>
      <H4>Enable bypass to safely perform repairs.</H4>
      <b>Select bypass mode</b>
      <ModeGroup>
        <ModeSelectionLabel active={irsBypassMode === 'IRRIGATION_STATION'} htmlFor="station">
          <b>Station Bypass</b>
          <p>Will prevent benches from moving to this irrigation station</p>
        </ModeSelectionLabel>
        <RadioInput
          onChange={handleChange}
          checked={irsBypassMode === 'IRRIGATION_STATION'}
          id="station"
          name="bypass"
          type="radio"
          value="IRRIGATION_STATION"
        />
      </ModeGroup>
      <ModeGroup>
        <ModeSelectionLabel
          active={irsBypassMode === 'IRRIGATION_STATION_WATER_ONLY'}
          htmlFor="water"
        >
          <b>Water Bypass</b>
          <p>Will prevent water entering this station while station is still usable</p>
        </ModeSelectionLabel>
        <RadioInput
          onChange={handleChange}
          checked={irsBypassMode === 'IRRIGATION_STATION_WATER_ONLY'}
          id="water"
          name="bypass"
          type="radio"
          value="IRRIGATION_STATION_WATER_ONLY"
        />
      </ModeGroup>
      <ModeGroup>
        <ModeSelectionLabel active={irsBypassMode === 'IRRIGATION_STATION_TILT'} htmlFor="tilt">
          <b>Tilt Bypass</b>
          <p>Will prevent tilt mechanism while station is still usable</p>
        </ModeSelectionLabel>
        <RadioInput
          onChange={handleChange}
          checked={irsBypassMode === 'IRRIGATION_STATION_TILT'}
          id="tilt"
          name="bypass"
          type="radio"
          value="IRRIGATION_STATION_TILT"
        />
      </ModeGroup>

      <ModalFooter>
        <DsButton
          label="Cancel"
          onClick={() => {
            setIrsBypassMode('');
            closeModal();
          }}
        />
        <DsButton
          primary
          label="Select"
          onClick={() => {
            if (irsBypassMode) {
              const irsMode = irsBypassMode?.toString();
              setIrsBypassMode('');
              confirmBypass(irsMode);
            }
          }}
        />
      </ModalFooter>
    </div>
  );
};
