import { DsButton, DsModal } from '@infarm/design-system-react/dist/components';
import {
  FarmStatus,
  PhysicalFarmModel,
  FarmCommandPart,
  FarmCommand
} from '@infarm/faas-middleware-types';
import { FunctionComponent, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { isInstoreFarm } from '../../lib/utils';
import { CommandTriggerAttempt } from './types';

interface ModalCopy {
  heading?: string;
  content?: string;
  primaryCaption: string;
  secondaryCaption: string;
}

const defaultCopy = {
  secondaryCaption: 'Cancel',
  primaryCaption: 'Confirm'
};

const getMaintenanceModeConfirmCopy = (
  status: FarmStatus,
  farmType: PhysicalFarmModel
): ModalCopy => {
  if (status === FarmStatus.Maintenance) {
    return {
      ...defaultCopy,
      heading: 'Are you sure you want to exit maintenance mode?',
      content: isInstoreFarm(farmType)
        ? 'Please ensure the farm is safe to return to production before exiting Maintenance Mode.'
        : '<b>Risk of injury</b><br/>Please confirm it is safe to exit maintenance mode'
    };
  }
  return {
    ...defaultCopy,
    heading: 'Are you sure you want to enter maintenance mode?',
    content:
      'Please be aware that this action will pause all operations that ensure the plants growing cycle. <b>Do not forget to exit Maintenance Mode when you are done.</b>'
  };
};

const getActivateSubsystemCopy = (farmCommandPart: FarmCommandPart): ModalCopy => {
  const commandName = farmCommandPart === FarmCommandPart.EcDosing ? 'EC' : 'pH';
  return {
    heading: `Are you sure you want to enable ${commandName} auto-dosing?`,
    content:
      farmCommandPart === FarmCommandPart.EcDosing
        ? 'Please ensure pH dosing is functional before disabling EC dosing bypass.'
        : '',
    primaryCaption: 'Confirm',
    secondaryCaption: 'Cancel'
  };
};

const getBypassSubsystemCopy = (farmCommandPart: FarmCommandPart): ModalCopy => {
  const commandName = farmCommandPart === FarmCommandPart.EcDosing ? 'EC' : 'pH';
  return {
    heading: `Are you sure you want to disable ${commandName} auto-dosing?`,
    content:
      farmCommandPart === FarmCommandPart.EcDosing
        ? 'Please be aware that this command will bypass both EC a & EC b dosing until you manually disable it.'
        : 'Please be aware that this command will bypass both pH - & pH+ dosing until you manually disable it.',
    primaryCaption: 'Confirm',
    secondaryCaption: 'Cancel'
  };
};

export const getModalCopy = (
  farmType: PhysicalFarmModel,
  commandTriggerAttempt: CommandTriggerAttempt,
  status?: FarmStatus
): ModalCopy => {
  const { farmCommand, farmCommandPart } = commandTriggerAttempt;
  if (farmCommand && status) {
    if (farmCommandPart === FarmCommandPart.Maintenance) {
      return getMaintenanceModeConfirmCopy(status, farmType);
    } else if (farmCommand === FarmCommand.BypassSubsystem) {
      return getBypassSubsystemCopy(farmCommandPart!);
    } else if (farmCommand === FarmCommand.ActivateSubsystem) {
      return getActivateSubsystemCopy(farmCommandPart!);
    }
  }
  return defaultCopy;
};

interface ConfirmFarmCommandModalProps {
  status?: FarmStatus;
  farmType: PhysicalFarmModel;
  commandTriggerAttempt: CommandTriggerAttempt;
  open: boolean;
  triggerCommand: () => void;
  commandModalClosed: () => void;
}

const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  ds-button {
    margin-left: 16px;
  }
`;

const Content = styled.p`
  padding-bottom: 16px;
`;

export const ConfirmFarmCommandModal: FunctionComponent<ConfirmFarmCommandModalProps> = ({
  status,
  farmType,
  commandTriggerAttempt,
  open,
  triggerCommand,
  commandModalClosed
}) => {
  const modalRef = useRef<HTMLDsModalElement>(null);

  useEffect(() => {
    const element = modalRef.current;
    element?.addEventListener('closed', () => {
      commandModalClosed();
    });
    return () => {
      element?.removeEventListener('closed', () => {});
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { heading, content, primaryCaption, secondaryCaption } = getModalCopy(
    farmType,
    commandTriggerAttempt,
    status
  );

  return (
    <DsModal hideCloseButton={true} open={open} heading={heading} ref={modalRef}>
      <Content dangerouslySetInnerHTML={{ __html: `${content}` }}></Content>
      <ModalFooter>
        {!!secondaryCaption && (
          <DsButton
            onClick={commandModalClosed}
            secondary
            label={secondaryCaption}
            data-cy="confirm-farm-command-modal___secondary"
          />
        )}
        {!!primaryCaption && (
          <DsButton
            onClick={triggerCommand}
            primary
            label={primaryCaption}
            data-cy="confirm-farm-command-modal___primary"
          />
        )}
      </ModalFooter>
    </DsModal>
  );
};
