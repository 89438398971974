import { FarmCommandPart, FarmStatus, UserAction } from '@infarm/faas-middleware-types';

import EnteringMaintenanceIcon from '../../assets/farm-commands/entering_maintenance.svg';
import ExitingMaintenanceIcon from '../../assets/farm-commands/exiting_maintenance.svg';
import DosingEnabled from '../../assets/farm-commands/dosing_enabled.svg';
import DosingDisabled from '../../assets/farm-commands/dosing_disabled.svg';
import IRSActive from '../../assets/farm-commands/irs_active.svg';
import IRSBypassed from '../../assets/farm-commands/irs_bypassed.svg';
import { BypassCommandAction, CardCopy, IrsState } from './types';

export const getCardCopy = (
  part: FarmCommandPart,
  isActivated: boolean,
  state: FarmStatus
): CardCopy => {
  let title = `${part === FarmCommandPart.EcDosing ? 'EC' : 'pH'} auto-dosing`;
  let icon = isActivated ? DosingDisabled : DosingEnabled;
  let button = isActivated ? 'Turn on' : 'Turn off';
  let status = isActivated ? 'Bypassed' : 'Enabled';
  let icon_inactive;

  if (part === FarmCommandPart.Maintenance) {
    title = 'Maintenance Mode';
    icon = isActivated ? ExitingMaintenanceIcon : EnteringMaintenanceIcon;
    button = isActivated ? 'Exit' : 'Enter Maintenance Mode';
    status = state === FarmStatus.Maintenance ? 'Enabled' : 'Disabled';
  }

  if (part === FarmCommandPart.IrsBypass1 || part === FarmCommandPart.IrsBypass2) {
    title = 'IRS 2 - top';
    icon = isActivated ? IRSBypassed : IRSActive;
    button = isActivated ? 'Disable Bypass' : 'Select Bypass';
    status = state === FarmStatus.Maintenance ? 'Tilt bypassed' : 'Active';
    icon_inactive = IRSBypassed;
  }

  return {
    title,
    icon,
    button,
    status,
    icon_inactive
  };
};

export const formattedDate = (updatedAt: string): string => {
  const date = new Date(Number(updatedAt));

  const formatOptions: any = {
    dateStyle: 'long',
    timeStyle: 'long'
  };
  const parts: Intl.DateTimeFormatPart[] = new Intl.DateTimeFormat(
    'en-US',
    formatOptions
  ).formatToParts(date);
  const month = parts.find(part => part.type === 'month')?.value;
  const day = parts.find(part => part.type === 'day')?.value;
  const hour = parts.find(part => part.type === 'hour')?.value;
  const minute = parts.find(part => part.type === 'minute')?.value;
  const dayPeriod = parts.find(part => part.type === 'dayPeriod')?.value;
  const timeZoneName = parts.find(part => part.type === 'timeZoneName')?.value;
  return `${month} ${day} at ${hour}:${minute}${dayPeriod} (${timeZoneName})`;
};

export const lastUpdatedRecipeFormat = (userAction?: UserAction) => {
  return userAction
    ? `Last edit on ${formattedDate(userAction.updatedAt)} by ${userAction.user}`
    : '';
};

export const getIrsStatusFromState = (irsState: IrsState): string => {
  switch (irsState) {
    case IrsState.BYPASS:
      return 'Station Bypass';
    case IrsState.WATER:
      return 'Water Bypass';
    case IrsState.TILT:
      return 'Tilt Bypass';
    default:
      return 'Active';
  }
};

export const getIrsCommandFromState = (irsState: IrsState) => {
  switch (irsState) {
    case IrsState.BYPASS:
      return 'IRRIGATION_STATION';
    case IrsState.TILT:
      return 'IRRIGATION_STATION_TILT';
    case IrsState.WATER:
      return 'IRRIGATION_STATION_WATER_ONLY';
    default:
      return 'IRRIGATION_STATION';
  }
};

export const getIrsState = (farmStateData: any, irrigationStationId: number): IrsState => {
  const irrigationSystem = farmStateData?.subsystemsState?.acre?.irrigationStations.find(
    (irrigationStation: any) => irrigationStation.stationId === irrigationStationId
  );
  if (irrigationSystem?.tiltState === 'BYPASSED' && irrigationSystem?.state === 'ACTIVE')
    return IrsState.TILT;
  if (irrigationSystem?.state === 'STATION_BYPASSED') return IrsState.BYPASS;
  if (irrigationSystem?.state === 'WATER_BYPASSED') return IrsState.WATER;
  if (irrigationSystem?.state === 'ACTIVE' && irrigationSystem?.tiltState === 'ACTIVE')
    return IrsState.ACTIVE;
  return IrsState.ERROR;
};

export const prepCommandMutationVars = (
  commandAction: BypassCommandAction,
  irsCommand_: string,
  currentIrsStation: number,
  farmUuid: string
): object => {
  // IRRIGATION_STATION_WATER_ONLY is only for enabling the bypass, we turn it back on with IRRIGATION_STATION
  const irsCommand =
    irsCommand_ === 'IRRIGATION_STATION_WATER_ONLY' && commandAction !== BypassCommandAction.ENABLE
      ? 'IRRIGATION_STATION'
      : irsCommand_;
  const irsBypassFarmPart =
    currentIrsStation === 1 ? FarmCommandPart.IrsBypass1 : FarmCommandPart.IrsBypass2;
  return {
    input: {
      farmUuid,
      command:
        commandAction === BypassCommandAction.ENABLE ? 'BYPASS_SUBSYSTEM' : 'ACTIVATE_SUBSYSTEM',
      farmCommandPart: irsBypassFarmPart,
      bypassParams: {
        farmSubsystem: irsCommand,
        irrigationStationId: currentIrsStation
      }
    }
  };
};
