import styled from 'styled-components';
import { FunctionComponent } from 'react';
import { getDisplayFarmTypeAndModel, formatGrafanaLink } from '../../pages/utils';
import { OrganizationalFarm } from './types';
import { CustomElement } from '../CustomElement';
import { SerialNumber } from './SerialNumber';
import { WATCH_FARM_STATE } from '../../lib/queries';
import { FarmState, PhysicalFarmModel } from '@infarm/faas-middleware-types';
import { formatFarmName, formatLocationName, makeKompostFarmURN } from './utils';
import { useSubscription } from '@apollo/client';
import { DsButton } from '@infarm/design-system-react';
import { isInstoreFarm } from '../../lib/utils';

const FarmRowContainer = styled.tr`
  align-items: center;
  display: grid;
  grid-template-rows: repeat(1, auto);
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  border: 1px solid #e7e7e7;
  border-top: none;
  padding: 4px 0;
  --color-mid: #767676;
`;

interface FarmCellType {
  span: number;
  flex?: boolean;
  hide?: boolean;
}

interface FarmIconType {
  svg?: string;
}
const FarmCell = styled.td<FarmCellType>`
  position: relative;
  grid-column: span ${props => props.span} / auto;
  padding: 4px 16px;
  align-content: center;
  display: ${props => (props.flex ? 'flex' : props.hide ? 'none' : 'table-cell')};
  align-items: center;
`;

const Subtitle = styled.span`
  display: block;
  font-size: 14px;
  color: var(--color-mid);
  line-height: 1em;
`;

const FarmIcon = styled.div<FarmIconType>`
  height: 37px;
  min-width: 48px;
  background-image: url(${props => props.svg});
  margin-right: 15px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
`;

const FarmName = styled.span`
  max-width: 250px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const MetricsLinkComponent: FunctionComponent<{ serialNumber: string | undefined }> = ({
  serialNumber
}) => {
  return serialNumber ? (
    <a rel="noreferrer" target="_blank" href={formatGrafanaLink(serialNumber)}>
      <DsButton style={{ fontSize: '16px' }} icon="open_in_new" flat={true} label="View Metrics" />
    </a>
  ) : (
    <></>
  );
};

export const FarmRow: FunctionComponent<{
  farm: OrganizationalFarm;
  openSerialLinkModal: Function;
  hiddenColumns?: string[];
}> = ({ farm, openSerialLinkModal, hiddenColumns = [] }) => {
  const { physicalFarmModel, location, serialNumber, model } = farm;

  const farmName = formatFarmName(farm);
  const locationName = formatLocationName(farm);

  const openModal = (farmUuid: string) => {
    openSerialLinkModal(farmUuid);
  };

  const locationGroupName =
    location && location.locationGroup ? <Subtitle>{location.locationGroup.name}</Subtitle> : null;

  const { farmType, farmVersion, svg } = getDisplayFarmTypeAndModel(
    physicalFarmModel as PhysicalFarmModel,
    model
  );

  const { data } = useSubscription(WATCH_FARM_STATE, { variables: { serialNumber: serialNumber } });
  const farmState: FarmState = data?.farmState;

  return (
    <FarmRowContainer>
      <FarmCell flex={true} span={5}>
        <FarmIcon svg={svg} />
        <FarmName>{farmName}</FarmName>
      </FarmCell>
      <FarmCell hide={hiddenColumns.includes('id')} span={4}>
        <SerialNumber
          data-cy={`FarmRow___SerialNumber___open___${serialNumber}`}
          serialNumber={serialNumber}
          farmUrn={makeKompostFarmURN(farm.uuid)}
          canAssignNewSerialNumber={!farm.serialNumber && !!farm.operationalFarm?.uuid}
          clickFn={openModal}
        />
      </FarmCell>
      <FarmCell hide={hiddenColumns.includes('type')} span={1}>
        <span>
          {isInstoreFarm(farmType) ? 'INSF' : 'ACRE'}
          <Subtitle>{farmVersion}</Subtitle>
        </span>
      </FarmCell>
      <FarmCell hide={hiddenColumns.includes('status')} span={3}>
        <CustomElement element="farm-status-chip" props={{ farmStatus: farmState?.state }} />
      </FarmCell>
      <FarmCell hide={hiddenColumns.includes('connectivity')} span={2}>
        <CustomElement element="farm-connectivity-icon" props={{ isOnline: farmState?.isOnline }} />
      </FarmCell>
      <FarmCell hide={hiddenColumns.includes('location')} span={7}>
        {locationName}
        {locationGroupName}
      </FarmCell>
      <FarmCell hide={hiddenColumns.includes('link')} span={3}>
        <MetricsLinkComponent serialNumber={serialNumber} />
      </FarmCell>
    </FarmRowContainer>
  );
};
