import { useMutation, useQuery } from '@apollo/client';
import { DsButton, DsPlaceholder, DsRow, DsSnackbar, DsSpacer } from '@infarm/design-system-react';
import { GET_WCS_CONFIG, SET_WCS_CONFIG } from '../../../lib/queries';
import { Link } from 'react-router-dom';
import { userCanEditRecipes } from '../../../lib/access-control';
import { useEffect, useState } from 'react';
import { RecipeField } from './RecipeField';
import { PhysicalFarm } from '@infarm/faas-middleware-types';
import { RecipeCard, RecipeModal } from './styled-components';

interface AcreWaterRecipeProps {
  serialNumber: string;
}

export const AcreWaterRecipeCard = ({ serialNumber }: AcreWaterRecipeProps) => {
  const { data, loading, error } = useQuery(GET_WCS_CONFIG, { variables: { serialNumber } });
  const isControlled = data?.physicalFarm?.hardwareConfiguration?.wcs?.isControlled ?? true;
  const wcsFarm = data?.physicalFarm?.hardwareConfiguration?.wcs?.controlledBy;
  const wcsConfig = wcsFarm?.config?.global?.wcs;
  const userCanEditRecipesValue = userCanEditRecipes();
  const attachedFarms = data?.physicalFarm?.hardwareConfiguration?.wcs?.attachedFarms || [];

  const [editFormVisible, showEditForm] = useState(false);
  const [success, setSuccess] = useState(false);
  const [isDirty, setDirty] = useState(false);
  const [ph, setPh] = useState<number | null>(null);
  const [ec, setEc] = useState<number | null>(null);
  const [temperature, setTemperature] = useState<number | null>(null);

  const [setWcsConfig, mutationResult] = useMutation(SET_WCS_CONFIG, {
    refetchQueries: [GET_WCS_CONFIG]
  });

  useEffect(() => {
    if (mutationResult.called && mutationResult.data) {
      showEditForm(false);
      setSuccess(true);
    }
  }, [mutationResult.called, mutationResult.data]);

  const submitForm = () => {
    mutationResult.reset();
    setWcsConfig({
      variables: { input: { serialNumber: wcsFarm.serialNumber, wcs: { ph, ec, temperature } } }
    });
  };

  useEffect(() => {
    if (!wcsConfig) return;
    setPh(wcsConfig.ph);
    setEc(wcsConfig.ec);
    setTemperature(wcsConfig.temperature);
    setDirty(false);
    if (editFormVisible) {
      setSuccess(false);
    }
  }, [editFormVisible, wcsConfig]);

  useEffect(() => {
    if (!wcsConfig || ph === null || ec === null || temperature === null) {
      setDirty(false);
      return;
    }
    if (ph !== wcsConfig.ph || ec !== wcsConfig.ec || temperature !== wcsConfig.temperature) {
      setDirty(true);
    }
  }, [wcsConfig, ph, ec, temperature]);

  return (
    <RecipeCard>
      <h3>Water Recipe</h3>
      {error ? (
        <>
          <p>Error loading water recipe:</p>
          <p>{error.message}</p>
        </>
      ) : loading ? (
        <DsPlaceholder></DsPlaceholder>
      ) : (
        <>
          {!isControlled && (
            <p>
              WCS is controlled by farm{' '}
              <Link to={`/farms/${wcsFarm?.serialNumber}/recipes-and-schedules/`}>
                {wcsFarm?.serialNumber}
              </Link>
            </p>
          )}
          <table>
            <thead>
              <tr>
                <td>Type</td>
                <td>Set point</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th data-title="Type">pH</th>
                <td data-title="Set point">{wcsConfig.ph}</td>
              </tr>
              <tr>
                <th data-title="Type">EC</th>
                <td data-title="Set point">{wcsConfig.ec} μS/cm</td>
              </tr>
              <tr>
                <th data-title="Type">Temperature</th>
                <td data-title="Set point">{wcsConfig.temperature} °C</td>
              </tr>
            </tbody>
          </table>
          <DsSpacer></DsSpacer>
          {userCanEditRecipesValue && (
            <>
              <footer>
                <DsButton
                  data-cy={`recipe-card___editWaterRecipe`}
                  label="Edit"
                  onClick={() => showEditForm(true)}
                />
              </footer>
              <RecipeModal
                heading="Edit Water Recipe"
                width={600}
                open={editFormVisible}
                hideCloseButton
                onClosed={() => showEditForm(false)}
              >
                <p>
                  Water recipe changes will affect multiple Acres:{' '}
                  {attachedFarms
                    .map((farm: PhysicalFarm) => (
                      <Link
                        to={`/farms/${farm.serialNumber}/recipes-and-schedules/`}
                        key={farm.serialNumber}
                      >
                        {farm.serialNumber}
                      </Link>
                    ))
                    .reduce((prev: any, curr: any) => [prev, ', ', curr])}
                </p>
                <RecipeField
                  name="pH set point"
                  min={0}
                  max={14}
                  step={0.1}
                  value={ph}
                  onValue={setPh}
                ></RecipeField>
                <RecipeField
                  name="EC set point"
                  unit="μS/cm"
                  min={0}
                  max={5000}
                  value={ec}
                  onValue={setEc}
                ></RecipeField>
                <RecipeField
                  name="Temperature set point"
                  unit="°C"
                  min={15}
                  max={30}
                  value={temperature}
                  onValue={setTemperature}
                ></RecipeField>
                <footer>
                  <DsRow>
                    <DsSpacer></DsSpacer>
                    <DsButton stretch label="Cancel" onClick={() => showEditForm(false)}></DsButton>
                    <DsButton
                      stretch
                      primary
                      label="Apply"
                      disabled={!isDirty || mutationResult.loading}
                      onClick={submitForm}
                    ></DsButton>
                  </DsRow>
                </footer>
                {!wcsFarm?.isOnline && (
                  <p className="offline">
                    <strong>Farm {wcsFarm?.serialNumber} is offline:</strong> All changes will be
                    applied when the farm is back online
                  </p>
                )}
              </RecipeModal>
              {mutationResult.error && (
                <DsSnackbar
                  labelText={mutationResult.error.message}
                  open="true"
                  stacked
                ></DsSnackbar>
              )}
              {success && (
                <DsSnackbar
                  labelText="Water recipe has been updated successfully."
                  open="true"
                  stacked
                ></DsSnackbar>
              )}
            </>
          )}
        </>
      )}
    </RecipeCard>
  );
};
