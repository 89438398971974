import { FunctionComponent } from 'react';
import { EditableCell } from './cells/EditableCell';

import { dayDurationCellLabel } from '../../lib/utils';
import { ScheduleFrequency } from '@infarm/faas-middleware-types';
import { IrrigationsEditableCell } from './cells/IrrigationsEditableCell';

export interface IrrigationSchedule {
  type: ScheduleFrequency;
  interval?: number;
  runsPerDay?: number;
}

export type DynamicRecipeRowValues = {
  stage2Intensity?: number;
  stage3Intensity?: number;
  irrigationsPerDayLightsOff?: number;
  soakingTimeLightsOn?: number;
  soakingTimeLightsOff?: number;
  day: number;
  irrigationsPerLightsOn?: number | string;
  // this is used only for the last/infinity row
  // and is sort of a hacky way how to allow it to handle a situation
  // of having configured every-n days irrigation in the last irrigation cycle
  irrigateEveryNDays?: number;
};

export const DynamicRecipeRow: FunctionComponent<{
  rowValues: DynamicRecipeRowValues;
  startDay?: number;
  finalRow?: boolean;
}> = ({ rowValues, startDay = 1, finalRow = false }) => {
  const dayCellLabel = !finalRow ? dayDurationCellLabel(startDay) : '∞';
  return (
    <tr key={`day-${rowValues.day}`} data-cy={`DynamicRecipeRow-${rowValues.day}`}>
      <EditableCell
        parameters={{
          value: dayCellLabel,
          bold: true
        }}
      />
      <EditableCell
        parameters={{
          value: rowValues.stage2Intensity?.toString(),
          dataCy: `DynamicRecipeRow-${rowValues.day}-stage2Intensity`
        }}
      />
      <EditableCell
        edge
        parameters={{
          value: rowValues.stage3Intensity!.toString()
        }}
      />
      <IrrigationsEditableCell
        parameters={{
          irrigationsPerLightsOn: rowValues.irrigationsPerLightsOn,
          irrigateEveryNDays: rowValues.irrigateEveryNDays
        }}
        isFinalRow={finalRow}
      />
      <EditableCell
        edge
        parameters={{
          value: rowValues.soakingTimeLightsOn?.toString()
        }}
      />
      <EditableCell
        parameters={{
          value: rowValues.irrigationsPerDayLightsOff?.toString()
        }}
      />
      <EditableCell
        parameters={{
          value: rowValues.soakingTimeLightsOff?.toString()
        }}
        edge
      />
    </tr>
  );
};
