import { DsModal, DsTabContainer, DsTab, DsTextfield } from '@infarm/design-system-react';
import {
  createElement,
  FunctionComponent,
  useLayoutEffect,
  useRef,
  useState,
  useEffect
} from 'react';
import { FarmList } from '../components/farm-list/FarmList';
import { GroupedFarmList } from '../components/farm-list/GroupedFarmList';
import { UpdatedFarm } from '../components/farm-list/types';
import styled from 'styled-components';
import { useParams, useHistory } from 'react-router-dom';
import { OrganizationalFarmModel } from '@infarm/faas-middleware-types';
import { useDebounce } from '@react-hook/debounce';

interface TabParams {
  tab: string;
}

const Header = styled.h1`
  margin: 36px 0;
  line-height: 1;
  font-size: 32px;
`;

enum Tab {
  ALL = 'All',
  INHUB = 'InHub',
  INSTORE = 'InStore'
}

const SearchTextfield = styled(DsTextfield)`
  width: 300px;
`;

export const Farms: FunctionComponent = () => {
  const modalRef = useRef<HTMLDivElement>();
  const tabsRef = useRef<HTMLDsTabContainerElement>(null);
  const history = useHistory();

  const [selectedTab, setSelectedTab] = useState('');
  const [serialLinkModalOpen, setSerialLinkModalOpen] = useState(false);
  const [modalUrn, setModalUrn] = useState('');
  const [updatedFarmData, setUpdatedFarmData] = useState<UpdatedFarm>();
  const [searchString, setSearchString] = useDebounce('', 500);

  const openSerialLinkModal = (farmUrn: string) => {
    setModalUrn(farmUrn);
    setSerialLinkModalOpen(true);
  };
  const closeSerialLinkModal = () => {
    setModalUrn('');
    setSerialLinkModalOpen(false);
  };

  const updateData = (ev: CustomEvent) => {
    setUpdatedFarmData(ev?.detail as UpdatedFarm);
  };

  const changeTab = (e: any) => {
    const newTab = e.detail.routerLink.toLowerCase() as Tab;
    history.push(`/farms/${newTab}`);
    setSelectedTab(newTab);
  };

  const { tab } = useParams<TabParams>();

  useEffect(() => {
    document.title = 'Farmboard - Farms & Systems';
    setSelectedTab(tab);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useLayoutEffect(() => {
    const modal = modalRef?.current;
    modal?.addEventListener('stencilReactBus___serialNumberUpdate', updateData as EventListener);

    const tabs = tabsRef?.current;
    tabs?.addEventListener('changeTab', changeTab as EventListener);

    return () => {
      modal?.removeEventListener(
        'stencilReactBus___serialNumberUpdate',
        updateData as EventListener
      );
      tabs?.removeEventListener('changeTab', changeTab as EventListener);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Header>Farms &amp; Systems</Header>
      {!!(globalThis as any).features?.fsd_farms_and_systems_search && (
        <SearchTextfield
          data-cy="Farms___searchTextfield"
          type="search"
          icon="search"
          placeholder="Search Location"
          onInput={(e: React.FormEvent<HTMLDsTextfieldElement>) => {
            setSearchString((e.target as HTMLInputElement).value);
          }}
        />
      )}
      <DsTabContainer
        selected={Object.values(Tab)
          .map(tab => tab.toLowerCase())
          .indexOf(selectedTab)}
        ref={tabsRef}
        data-cy="Farms__Tabs"
      >
        <DsTab
          label={Tab.ALL}
          selected={selectedTab === Tab.ALL.toLowerCase()}
          routerLink={Tab.ALL}
          data-cy="Farms__Tab__ALL"
        >
          {selectedTab === Tab.ALL.toLowerCase() && (
            <FarmList
              isDisplayed={selectedTab === Tab.ALL.toLowerCase()}
              openSerialLinkModal={openSerialLinkModal}
              models={[OrganizationalFarmModel.Acre, OrganizationalFarmModel.Instore]}
              updatedFarmData={updatedFarmData}
              searchString={searchString}
            />
          )}
        </DsTab>

        <DsTab
          label={Tab.INHUB}
          selected={selectedTab === Tab.INHUB.toLowerCase()}
          routerLink={Tab.INHUB}
          data-cy="Farms__Tab__INHUB"
        >
          {selectedTab === Tab.INHUB.toLowerCase() && (
            <GroupedFarmList
              isDisplayed={selectedTab === Tab.INHUB.toLowerCase()}
              openSerialLinkModal={openSerialLinkModal}
              updatedFarmData={updatedFarmData}
              searchString={searchString}
            />
          )}
        </DsTab>
        <DsTab
          label={Tab.INSTORE}
          selected={selectedTab === Tab.INSTORE.toLowerCase()}
          routerLink={Tab.INSTORE}
          data-cy="Farms__Tab__INSTORE"
        >
          {' '}
          {selectedTab === Tab.INSTORE.toLowerCase() && (
            <FarmList
              isDisplayed={selectedTab === Tab.INSTORE.toLowerCase()}
              openSerialLinkModal={openSerialLinkModal}
              models={[OrganizationalFarmModel.Instore]}
              updatedFarmData={updatedFarmData}
              searchString={searchString}
            />
          )}
        </DsTab>
      </DsTabContainer>
      <DsModal
        width={440}
        hideCloseButton={true}
        heading="Add Farm ID"
        open={serialLinkModalOpen}
        onClosed={closeSerialLinkModal}
      >
        {createElement('farm-serial-link-modal', { urn: modalUrn, ref: modalRef })}
      </DsModal>
    </div>
  );
};
