import React from 'react';
import { useParams } from 'react-router-dom';
import kebabCase from 'lodash/kebabCase';

export const CustomElement: React.FunctionComponent<{ element: string; props?: object }> = ({
  element,
  props = {}
}) => {
  const params = useParams();
  const mixedProps = { ...params, ...props };
  let kebabCasedProps = {};
  Object.keys(mixedProps).forEach(propName => {
    const kebabCasedPropName = kebabCase(propName).toString();
    kebabCasedProps = {
      [kebabCasedPropName]: mixedProps[propName as keyof Object],
      ...kebabCasedProps
    };
  });
  return React.createElement(element, kebabCasedProps);
};
