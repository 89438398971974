import { DsButton } from '@infarm/design-system-react';
import styled from 'styled-components';

import { FunctionComponent, useRef } from 'react';
import { FETCH_ORGANIZATIONAL_FARMS, FETCH_LOCATIONS } from '../../lib/queries';
import {
  OrganizationalFarmQueryVariables,
  OrganizationalFarmFilterQueryVariables
} from '../../lib/types';
import { FarmRow } from './FarmRow';
import { FarmListHeader } from './FarmListHeader';
import { OrganizationalFarm, UpdatedFarm } from './types';
import { parseLocationResultsToOrganizationalFarms, updateFarmsList } from './utils';
import { useQuery } from '@apollo/client';
import { OrganizationalFarmModel } from '@infarm/faas-middleware-types';

const CenterRow = styled.td`
  width: 100%;
  display: flex;
  margin: 24px 0;
  justify-content: center;
`;

const LoadingMessage = styled.td`
  padding: 32px 0;
  color: #666;
`;

interface FarmsListProps {
  openSerialLinkModal: Function;
  models: OrganizationalFarmModel[];
  updatedFarmData?: UpdatedFarm;
  searchString?: string;
  isDisplayed: boolean;
}

const useFarmData = (
  isDisplayed: boolean,
  models: OrganizationalFarmModel[],
  searchString: string | undefined
) => {
  const fetchMoreFarms = (endCursor: string) => {
    fetchMore({
      variables: { ...queryVariables, cursor: endCursor }
    });
  };

  const farmFilter: OrganizationalFarmFilterQueryVariables = { model: models, isGen2: true };

  const queryVariables: OrganizationalFarmQueryVariables = {
    limit: 10,
    filter: !searchString ? farmFilter : { name: searchString },
    ...(!!searchString && {
      farmFilter: farmFilter
    })
  };

  const FETCH_FARM_QUERY = !searchString ? FETCH_ORGANIZATIONAL_FARMS : FETCH_LOCATIONS;
  const { data, loading, fetchMore, error } = useQuery(FETCH_FARM_QUERY, {
    variables: queryVariables,
    errorPolicy: 'all',
    notifyOnNetworkStatusChange: true, // required for `loading` to be updated when calling `fetchMore`
    skip: !isDisplayed
  });

  const { pageInfo } = data?.organizationalFarms || data?.locations || {};
  const farmData = !searchString
    ? data?.organizationalFarms?.edges
    : parseLocationResultsToOrganizationalFarms(data?.locations?.edges);

  return { farmData, loading, pageInfo, fetchMoreFarms, error };
};

export const FarmList: FunctionComponent<FarmsListProps> = ({
  openSerialLinkModal,
  models,
  updatedFarmData,
  searchString,
  isDisplayed
}) => {
  const loadingResultsInfo: string = !!searchString
    ? 'Loading search results...'
    : 'Loading results...';
  const farmDataLength = useRef(0);
  const { farmData, loading, pageInfo, fetchMoreFarms, error } = useFarmData(
    isDisplayed,
    models,
    searchString
  );

  if (!loading && farmData?.length === farmDataLength.current && pageInfo?.hasNextPage) {
    fetchMoreFarms(pageInfo?.endCursor);
  } else {
    farmDataLength.current = farmData?.length || 0;
  }

  const loadErrorMessage = !!error
    ? 'Failed to load, please try again.'
    : 'No results found for your search. Please try again with another Location.';

  return (
    <table>
      <thead>
        <FarmListHeader />
      </thead>
      <tbody>
        {!!farmData &&
          farmData.length > 0 &&
          updateFarmsList(farmData, updatedFarmData).map((farm: OrganizationalFarm) => (
            <FarmRow
              key={`farm-${farm.uuid}`}
              farm={farm}
              openSerialLinkModal={openSerialLinkModal}
            />
          ))}
        {loading && (
          <tr>
            <LoadingMessage colSpan={8}>{loadingResultsInfo}</LoadingMessage>
          </tr>
        )}
        {(!!error ||
          (!loading && !!farmData && farmData.length === 0 && !pageInfo?.hasNextPage)) && (
          <tr>
            <LoadingMessage colSpan={8}>{loadErrorMessage}</LoadingMessage>
          </tr>
        )}
      </tbody>
      <tfoot>
        <tr>
          <CenterRow colSpan={8}>
            <DsButton
              onClick={() => {
                fetchMoreFarms(pageInfo.endCursor);
              }}
              label="Show More"
              disabled={loading || !pageInfo?.hasNextPage}
              secondary
            />
          </CenterRow>
        </tr>
      </tfoot>
    </table>
  );
};
