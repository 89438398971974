import { DsButton, DsModal } from '@infarm/design-system-react/dist/components';
import { FarmStatus, FarmCommandPart, FarmCommand } from '@infarm/faas-middleware-types';
import { FunctionComponent, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { CommandTriggerAttempt } from './types';

interface ModalCopy {
  heading?: string;
  content?: string;
  primaryCaption: string;
  secondaryCaption: string | null;
}

const defaultCopy = {
  secondaryCaption: 'Cancel',
  primaryCaption: 'Confirm'
};

const getActivateSubsystemCopy = (commandName: string): ModalCopy => {
  return {
    heading: `${commandName} auto-dosing is being enabled...`,
    content: 'This might take a few seconds...',
    primaryCaption: 'OK',
    secondaryCaption: null
  };
};

const getBypassSubsystemCopy = (commandName: string): ModalCopy => {
  return {
    heading: `${commandName} auto-dosing is being disabled...`,
    content: 'This might take a few seconds...',
    primaryCaption: 'OK',
    secondaryCaption: null
  };
};

const getMaintenanceModeCopy = (status: FarmStatus): ModalCopy => {
  if (status === FarmStatus.Maintenance) {
    return {
      heading: 'Maintenance mode is being disabled...',
      content: 'This might take a few seconds...',
      primaryCaption: 'OK',
      secondaryCaption: null
    };
  } else if (status === FarmStatus.Running) {
    return {
      heading: 'Maintenance mode is being enabled...',
      content: 'This might take a few seconds...',
      primaryCaption: 'OK',
      secondaryCaption: null
    };
  } else if (status === FarmStatus.EnteringError) {
    return {
      heading: 'Maintenance Mode cannot be enabled',
      content:
        "You either don't have the right permissions to enable Maintenance Mode, the farm is not connected or the farm is in a state where Maintenance Mode can not be enabled.",
      primaryCaption: 'OK',
      secondaryCaption: null
    };
  }
  return {
    primaryCaption: 'OK',
    secondaryCaption: null
  };
};

export const getModalCopy = (
  commandTriggerAttempt: CommandTriggerAttempt,
  status?: FarmStatus
): ModalCopy => {
  if (commandTriggerAttempt) {
    const commandName =
      commandTriggerAttempt.farmCommandPart === FarmCommandPart.EcDosing ? 'EC' : 'pH';
    if (commandTriggerAttempt.farmCommand === FarmCommand.ActivateSubsystem) {
      return getActivateSubsystemCopy(commandName);
    } else if (commandTriggerAttempt.farmCommand === FarmCommand.BypassSubsystem) {
      return getBypassSubsystemCopy(commandName);
    } else if (status) {
      return getMaintenanceModeCopy(status);
    }
  }
  return defaultCopy;
};

interface WaitFarmCommandModalProps {
  status?: FarmStatus;
  commandTriggerAttempt: CommandTriggerAttempt;
  open: boolean;
  commandModalClosed: () => void;
}

const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  ds-button {
    margin-left: 16px;
  }
`;

export const WaitFarmCommandModal: FunctionComponent<WaitFarmCommandModalProps> = ({
  status,
  commandTriggerAttempt,
  open,
  commandModalClosed
}) => {
  const modalRef = useRef<HTMLDsModalElement>(null);

  useEffect(() => {
    const element = modalRef.current;
    element?.addEventListener('closed', () => {
      commandModalClosed();
    });
    return () => {
      element?.removeEventListener('closed', () => {});
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { heading, content, primaryCaption, secondaryCaption } = getModalCopy(
    commandTriggerAttempt,
    status
  );
  return (
    <DsModal hideCloseButton={true} open={open} heading={heading}>
      <p>{content}</p>
      <ModalFooter>
        {!!secondaryCaption && (
          <DsButton
            onClick={commandModalClosed}
            secondary
            label={secondaryCaption}
            data-cy="wait-farm-command-modal___secondary"
          />
        )}
        {!!primaryCaption && (
          <DsButton
            onClick={commandModalClosed}
            primary
            label={primaryCaption}
            data-cy="wait-farm-command-modal___primary"
          />
        )}
      </ModalFooter>
    </DsModal>
  );
};
