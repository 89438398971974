import { DocumentNode, useQuery } from '@apollo/client';
import { FarmConfigurationV2, PhysicalFarmModel, UserAction } from '@infarm/faas-middleware-types';
import { FunctionComponent, useEffect, useRef, useState } from 'react';
import {
  GET_INSTORE_V2_CONFIG,
  GET_INSTORE_V1_CONFIG,
  GET_ACRE_CONFIG_V2_QUERY
} from '../../lib/queries';
import { isInstoreFarm, stripTypenames } from '../../lib/utils';
import { CustomElement } from '../CustomElement';
import { cloneDeep } from 'apollo-utilities';
import { AcreRecipesAndSchedules } from './AcreRecipesAndSchedules';
import { FarmConfigurationError } from '../../lib/types';
import { InstoreRecipesAndSchedules } from './InstoreRecipesAndSchedules';

const getQuery = (physicalFarmModel: PhysicalFarmModel): DocumentNode => {
  switch (physicalFarmModel) {
    case PhysicalFarmModel.InStore:
      return GET_INSTORE_V2_CONFIG;
    case PhysicalFarmModel.InStoreV1:
      return GET_INSTORE_V1_CONFIG;
    default:
      return GET_ACRE_CONFIG_V2_QUERY;
  }
};

type RecipesAndSchedulesProps = {
  serialNumber: string;
  isOnline: boolean;
  userActions: UserAction[];
  physicalFarmModel: PhysicalFarmModel;
  snackbarCallback: (snackbarText: string) => void;
};

export const RecipesAndSchedules: FunctionComponent<RecipesAndSchedulesProps> = ({
  serialNumber,
  isOnline,
  userActions,
  physicalFarmModel,
  snackbarCallback
}) => {
  // we use a copy of the config that can be referenced in event callbacks and used to clean dirty values
  const cleanFarmConfig = useRef<FarmConfigurationV2>();
  const [farmConfig, setFarmConfig] = useState<FarmConfigurationV2>();
  const cleanDirtyConfig = () => {
    setFarmConfig(cloneDeep(cleanFarmConfig.current));
  };
  const setCleanFarmConfig = (config: FarmConfigurationV2) => {
    // clone is used to avoid the clean and dirty copies sharing the same reference
    cleanFarmConfig.current = cloneDeep(config);
  };

  const query = getQuery(physicalFarmModel);

  const { error, loading } = useQuery(query, {
    variables: { serialNumber },
    skip: !serialNumber,
    onCompleted: ({ farmConfigurationV2 }) => {
      const config = stripTypenames(farmConfigurationV2);
      setFarmConfig(config);
      setCleanFarmConfig(config);
    }
  });

  const recipeApplied = ({ detail: { config, recipeTitle } }: any) => {
    setFarmConfig(config);
    setCleanFarmConfig(config);
    const snackbarText = isOnline
      ? `${serialNumber} successfully updated`
      : `Pending ${recipeTitle.toLowerCase()} changes will be applied when the farm is online`;
    snackbarCallback(snackbarText);
  };

  const recipeAppliedEventName = 'stencilReactBus___recipeApplied';
  const modalClosedEventNames = ['stencilReactBus___plantRecipeClosed', 'closed'];
  const removeListeners = () => {
    window.removeEventListener(recipeAppliedEventName, recipeApplied);
    modalClosedEventNames.forEach(eventName => {
      window.removeEventListener(eventName, cleanDirtyConfig);
    });
  };

  useEffect(() => {
    removeListeners();
    window.addEventListener(recipeAppliedEventName, recipeApplied);
    modalClosedEventNames.forEach(eventName => {
      window.addEventListener(eventName, cleanDirtyConfig);
    });
    return () => {
      removeListeners();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOnline, serialNumber]);

  const farmConfigError = !!error?.graphQLErrors
    ? FarmConfigurationError.UNSUPPORTED_CONFIG
    : FarmConfigurationError.LOAD_ERROR;

  return (
    <>
      {!loading && (
        <>
          {isInstoreFarm(farmConfig?.farmType!) ? (
            <InstoreRecipesAndSchedules
              isOnline={isOnline}
              userActions={userActions}
              farmConfig={farmConfig}
            ></InstoreRecipesAndSchedules>
          ) : (
            <AcreRecipesAndSchedules
              serialNumber={serialNumber}
              isOnline={isOnline}
              userActions={userActions}
              farmConfig={farmConfig}
            ></AcreRecipesAndSchedules>
          )}
        </>
      )}
      {error && (
        <CustomElement
          element="farm-config-error-message"
          props={{
            error: farmConfigError
          }}
        />
      )}
    </>
  );
};
