import { FarmConfigurationV2, UserAction } from '@infarm/faas-middleware-types';
import { FunctionComponent, useEffect } from 'react';
import { DsColumn, DsRow, DsSpacer } from '@infarm/design-system-react';
import { CustomElement } from '../CustomElement';
import styled from 'styled-components';
import { AcreWaterRecipeCard } from './cards/AcreWaterRecipeCard';
import { AcreCo2RecipeCard } from './cards/AcreCo2RecipeCard';
import { AcreCracRecipeCard } from './cards/AcreCracRecipeCard';

type AcreRecipesAndSchedulesProps = {
  serialNumber: string;
  isOnline: boolean;
  userActions: UserAction[];
  farmConfig?: FarmConfigurationV2;
};

const PlantRecipesHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const AcreRecipesAndSchedules: FunctionComponent<AcreRecipesAndSchedulesProps> = ({
  isOnline,
  userActions,
  farmConfig,
  serialNumber
}) => {
  useEffect(() => {
    if (farmConfig) {
      const plantRecipes: any = document.querySelector('plant-recipes');
      if (plantRecipes && !!farmConfig) {
        plantRecipes.userActions = userActions;
        plantRecipes.farmConfig = farmConfig;
      }
    }
  }, [farmConfig, userActions]);

  return (
    <DsColumn>
      <DsRow>
        <h2>Global recipes</h2>
      </DsRow>
      {farmConfig && (
        <>
          <DsRow>
            <AcreWaterRecipeCard serialNumber={serialNumber}></AcreWaterRecipeCard>
            <AcreCo2RecipeCard serialNumber={serialNumber} isOnline={isOnline}></AcreCo2RecipeCard>
          </DsRow>
          <DsRow>
            <AcreCracRecipeCard
              serialNumber={serialNumber}
              isOnline={isOnline}
            ></AcreCracRecipeCard>
            <DsSpacer></DsSpacer>
          </DsRow>
          <DsRow>
            <PlantRecipesHeader>
              <h2>Plant recipes</h2>
            </PlantRecipesHeader>
          </DsRow>
          <CustomElement
            element={'plant-recipes'}
            props={{
              isOnline
            }}
          />
        </>
      )}
    </DsColumn>
  );
};
