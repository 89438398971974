import { useQuery } from '@apollo/client';
import { FarmStateResponse } from '@infarm/faas-middleware-types';
import { useEffect, useState } from 'react';
import { GET_FARM_STATE } from './queries';

export const useFarmState = (serialNumber: string, includeSubsystemsState: boolean = false) => {
  const [farmState, setFarmState] = useState<FarmStateResponse>();

  const { data, startPolling, stopPolling, error } = useQuery(GET_FARM_STATE, {
    variables: {
      farmUuid: serialNumber,
      includeSubsystemsState
    }
  });

  startPolling(3000);
  useEffect(() => {
    if (data) {
      const { farmState } = data;
      setFarmState(farmState);
    }
    return () => stopPolling();
  }, [data, farmState, setFarmState, stopPolling]);

  return { farmState, stopPollingFarmState: stopPolling, farmStateError: error };
};
