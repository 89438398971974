export enum MaintenancePermissions {
  ENABLE_DISABLE,
  ENABLE_ONLY,
  NONE
}

export interface OrganizationalFarmFilterQueryVariables {
  model: string[];
  isGen2: boolean;
}
export interface OrganizationalFarmQueryVariables {
  cursor?: string;
  limit?: number;
  filter: object;
  farmFilter?: OrganizationalFarmFilterQueryVariables;
}

export enum FarmConfigurationError {
  LOAD_ERROR = 1,
  UNSUPPORTED_CONFIG = 2
}
