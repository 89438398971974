import {
  FarmCommand,
  FarmCommandPart,
  FarmStateResponse,
  FarmStatus,
  PhysicalFarmModel,
  UserAction,
  SubsystemState
} from '@infarm/faas-middleware-types';
import { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { SEND_FARM_COMMAND } from '../../lib/queries';
import { MaintenancePermission } from '../../pages/types';
import { isInstoreFarm, userCanToggleMaintenance } from '../../lib/utils';
import { CommandTriggerAttempt } from './types';
import { FarmControl } from './FarmControl';
import { DsCard } from '@infarm/design-system-react';
import { getCardCopy } from './utils';
import { ConfirmFarmCommandModal } from './ConfirmFarmCommandModal';
import { WaitFarmCommandModal } from './WaitFarmCommandModal';
import styled from 'styled-components';
import { useMutation } from '@apollo/client';
import { IrrigationSystem } from './IrrigationSystem';

const isMaintenanceModeTransitionComplete = (
  commandTriggerAttempt: CommandTriggerAttempt,
  state: FarmStatus
): boolean => {
  const enableMaintenanceModeTransitionComplete =
    commandTriggerAttempt?.farmCommand === FarmCommand.EnableMaintenanceMode &&
    state === FarmStatus.Maintenance;
  const disableMaintenanceModeTransitionComplete =
    commandTriggerAttempt?.farmCommand === FarmCommand.DisableMaintenanceMode &&
    state === FarmStatus.Running;
  return enableMaintenanceModeTransitionComplete || disableMaintenanceModeTransitionComplete;
};

const isDosingTransitionComplete = (
  commandTriggerAttempt: CommandTriggerAttempt,
  state: FarmStateResponse
): boolean => {
  const { farmCommandPart, farmCommand } = commandTriggerAttempt;
  const { wcs } = state.subsystemsState?.instore!;
  const ecDosingState = wcs?.ecDosing;
  const phDosingState = wcs?.phDosing;
  const enableEcBypassTransitionComplete =
    farmCommandPart === FarmCommandPart.EcDosing &&
    farmCommand === FarmCommand.BypassSubsystem &&
    ecDosingState === SubsystemState.Bypassed;
  const disableEcBypassTransitionComplete =
    farmCommandPart === FarmCommandPart.EcDosing &&
    farmCommand === FarmCommand.ActivateSubsystem &&
    ecDosingState === SubsystemState.Active;
  const enablePhBypassTransitionComplete =
    farmCommandPart === FarmCommandPart.PhDosing &&
    farmCommand === FarmCommand.BypassSubsystem &&
    phDosingState === SubsystemState.Bypassed;
  const disablePhBypassTransitionComplete =
    farmCommandPart === FarmCommandPart.PhDosing &&
    farmCommand === FarmCommand.ActivateSubsystem &&
    phDosingState === SubsystemState.Active;
  return (
    disableEcBypassTransitionComplete ||
    enableEcBypassTransitionComplete ||
    enablePhBypassTransitionComplete ||
    disablePhBypassTransitionComplete
  );
};

const getSnackbarText = (commandTriggerAttempt: CommandTriggerAttempt) => {
  if (commandTriggerAttempt.farmCommandPart === FarmCommandPart.Maintenance) {
    return `Maintenance mode ${
      commandTriggerAttempt.farmCommand === FarmCommand.EnableMaintenanceMode
        ? 'enabled'
        : 'disabled'
    }`;
  }
  return `${
    commandTriggerAttempt.farmCommandPart === FarmCommandPart.EcDosing ? 'EC' : 'pH'
  } auto-dosing successfully ${
    commandTriggerAttempt.farmCommand === FarmCommand.ActivateSubsystem ? 'activated' : 'bypassed'
  }`;
};

const grey = '#f2f2f2';

const ControlSections = styled.div`
  display: flex;
  gap: 1rem;
  align-items: flex-start;
  margin-top: 16px;
`;

const ControlSection = styled.div`
  width: 30%;
`;

const Header = styled.header`
  border-bottom: 1px solid ${grey};
  padding: 1.25rem;
  font-size: 1.25rem;
  padding-bottom: 16px;
`;

const ControlContainer = styled.div`
  :not(:first-child) {
    border-top: 1px solid ${grey};
  }
`;

const Card = styled(DsCard)`
  padding: 0;
`;

interface FarmControlsProps {
  userActions: UserAction[];
  maintenancePermission: MaintenancePermission;
  farmState: FarmStateResponse;
  serialNumber: string;
  farmType: PhysicalFarmModel;
  snackbarCallback: (text: string) => void;
  reloadUserActions: () => void;
}

export const FarmControls: FunctionComponent<FarmControlsProps> = ({
  userActions,
  maintenancePermission,
  farmState,
  serialNumber,
  farmType,
  snackbarCallback,
  reloadUserActions
}) => {
  const [commandTriggerAttempt, setCommandTriggerAttempt] = useState<CommandTriggerAttempt>({});
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isWaitModalOpen, setIsWaitModalOpen] = useState(false);

  const [sendFarmCommand] = useMutation(SEND_FARM_COMMAND);

  useEffect(() => {
    const setCommandProcessingComplete = () => {
      setCommandTriggerAttempt({
        ...commandTriggerAttempt,
        commandBeingProcessed: false
      });
    };
    if (farmState) {
      const dosingTransitionComplete =
        isInstoreFarm(farmType) &&
        commandTriggerAttempt &&
        isDosingTransitionComplete(commandTriggerAttempt, farmState);
      const { state } = farmState;
      if (
        !!commandTriggerAttempt?.commandBeingProcessed &&
        (isMaintenanceModeTransitionComplete(commandTriggerAttempt, state) ||
          dosingTransitionComplete)
      ) {
        setCommandProcessingComplete();
        const snackbarText = getSnackbarText(commandTriggerAttempt);
        snackbarCallback(snackbarText);
      }
    }
  }, [farmState, isConfirmModalOpen, farmType, snackbarCallback, commandTriggerAttempt]);

  const getUserAction = (farmCommandPart: FarmCommandPart): UserAction | undefined => {
    return userActions.find(action => action.section === farmCommandPart);
  };

  const commandModalOpened = (commandTiggerAttempt: CommandTriggerAttempt) => {
    setCommandTriggerAttempt({
      ...commandTiggerAttempt,
      commandBeingProcessed: false
    });
    setIsConfirmModalOpen(true);
  };

  const confirmModalClosed = () => {
    setIsConfirmModalOpen(false);
  };

  const waitModalClosed = () => {
    setIsWaitModalOpen(false);
  };

  const commandTriggered = () => {
    setCommandTriggerAttempt({
      ...commandTriggerAttempt,
      commandBeingProcessed: true
    });
    setIsConfirmModalOpen(false);
    setIsWaitModalOpen(true);
    const { farmCommand: command, farmCommandPart } = commandTriggerAttempt!;
    sendFarmCommand({
      variables: {
        input: {
          farmUuid: serialNumber,
          command,
          farmCommandPart
        }
      }
    });
  };

  const isControlActivated = (farmCommandPart: FarmCommandPart): boolean => {
    if (farmState) {
      const { state } = farmState;
      if (farmCommandPart === FarmCommandPart.Maintenance) {
        return state === FarmStatus.Maintenance;
      } else if (farmCommandPart === FarmCommandPart.EcDosing) {
        return farmState?.subsystemsState?.instore?.wcs?.ecDosing === SubsystemState.Bypassed;
      } else if (farmCommandPart === FarmCommandPart.PhDosing) {
        return farmState?.subsystemsState?.instore?.wcs?.phDosing === SubsystemState.Bypassed;
      }
    }
    return false;
  };

  const isFarmInTransition =
    !!commandTriggerAttempt?.commandBeingProcessed ||
    farmState?.state === FarmStatus.EnteringMaintenance ||
    farmState?.state === FarmStatus.ExitingMaintenance;

  const commandsCanBeIssued =
    farmState &&
    userCanToggleMaintenance(
      farmState?.isOnline,
      isFarmInTransition,
      maintenancePermission,
      farmState.state
    );

  const controlSection: FarmCommandPart[][] = isInstoreFarm(farmType)
    ? [[FarmCommandPart.Maintenance], [FarmCommandPart.EcDosing, FarmCommandPart.PhDosing]]
    : [[FarmCommandPart.Maintenance]];

  return (
    <>
      <ConfirmFarmCommandModal
        status={farmState?.state}
        farmType={farmType}
        commandTriggerAttempt={commandTriggerAttempt}
        open={isConfirmModalOpen}
        triggerCommand={commandTriggered}
        commandModalClosed={confirmModalClosed}
      ></ConfirmFarmCommandModal>
      <WaitFarmCommandModal
        status={farmState?.state}
        commandTriggerAttempt={commandTriggerAttempt}
        open={isWaitModalOpen}
        commandModalClosed={waitModalClosed}
      ></WaitFarmCommandModal>
      <h2>Controls</h2>
      <ControlSections>
        {controlSection.map((farmCommandParts, i) => {
          let header = '' + i;
          if (farmCommandParts.indexOf(FarmCommandPart.EcDosing) > -1) {
            header = 'Auto-Dosing';
          }
          if (farmCommandParts.indexOf(FarmCommandPart.Maintenance) > -1) {
            header = 'Farm Mode';
          }

          return (
            <Fragment key={header}>
              <ControlSection key={header}>
                <Card key={header}>
                  <Header>{header}</Header>
                  {farmCommandParts.map(farmCommandPart => {
                    const isActivated = isControlActivated(farmCommandPart);
                    const userAction = getUserAction(farmCommandPart);
                    const copy = getCardCopy(farmCommandPart, isActivated, farmState?.state);
                    return (
                      <ControlContainer key={farmCommandPart}>
                        <FarmControl
                          farmCommandPart={farmCommandPart}
                          disabled={!commandsCanBeIssued}
                          isActivated={isActivated}
                          userAction={userAction}
                          commandModalOpened={commandModalOpened}
                          copy={copy}
                          key={farmCommandPart}
                        />
                      </ControlContainer>
                    );
                  })}
                </Card>
              </ControlSection>

              {!isInstoreFarm(farmType) && (globalThis as any).features?.irs_bypass && (
                <ControlSection key={'cs-irs-bypass'}>
                  <Card key={'card-irs-bypass'}>
                    <Header>Irrigation System</Header>
                    <ControlContainer key={'cc-irs-bypass'}>
                      <IrrigationSystem
                        reloadUserActions={reloadUserActions}
                        userActions={userActions}
                        farmUuid={serialNumber}
                        farmState={farmState}
                        disabled={!commandsCanBeIssued}
                      />
                    </ControlContainer>
                  </Card>
                </ControlSection>
              )}
            </Fragment>
          );
        })}
      </ControlSections>
    </>
  );
};
