import { FarmCommand, FarmCommandPart } from '@infarm/faas-middleware-types';

export type CommandTriggerAttempt = {
  farmCommand?: FarmCommand; // what do we want to do to the farm?
  farmCommandPart?: FarmCommandPart; // what bit of the farm are we talking about
  commandBeingProcessed?: boolean; // is this command being processed by the farm?
};

export type ControlCopy = {
  title: string;
  status: string;
  button: string;
  icon: string;
};

export type CardCopy = {
  title: string;
  icon: string;
  icon_inactive?: string;
  button: string;
  status: string;
};

export enum ConfirmModalSteps {
  CONFIRM = 1,
  POST_CONFIRM,
  DISABLE,
  POST_DISABLE
}

export enum IrsState {
  ACTIVE = 1,
  BYPASS,
  WATER,
  TILT,
  ERROR
}

export enum BypassCommandAction {
  ENABLE,
  DISABLE
}
