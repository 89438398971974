import { OrganizationalFarm, UserAction, FarmStateResponse } from '@infarm/faas-middleware-types';
import React, { useState, useEffect, SyntheticEvent, useRef } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { decideMaintenancePermission, farmUri, formatGrafanaLink, getUserActions } from './utils';
import { useQuery } from '@apollo/client';
import { CustomElement } from '../components/CustomElement';

import {
  DsButton,
  DsPlaceholder,
  DsSnackbar,
  DsTab,
  DsTabContainer
} from '@infarm/design-system-react';
import { GET_ORGANIZATIONAL_FARM_QUERY } from '../lib/queries';
import { MaintenancePermission } from './types';
import { getUser } from '@infarm/auth';
import { FarmInfo } from '../components/farm/FarmInfo';
import styled from 'styled-components';
import { FarmControls } from '../components/farm-controls/FarmControls';
import { RecipesAndSchedules } from '../components/recipes-and-schedules/RecipesAndSchedules';
import { useFarmState } from '../lib/hooks';

export enum Tab {
  Controls = 'controls',
  RecipesAndSchedules = 'recipes & schedules'
}

const BreadcrumbsContainer = styled.div`
  width: 100%;
  font-size: 16px;
  padding: 10px 0;
  color: #767676;
`;

const ButtonsContainer = styled.div`
  ds-button {
    margin-left: 20px;
  }
`;

const LinkNoDecoration = styled.a`
  text-decoration: none !important;
`;

const FarmHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
  padding: 10px 0 80px 0;
`;

type FarmProps = {
  snackbarText?: string;
  setSnackbarText: (message?: string) => void;
};

export const Farm: React.FunctionComponent<FarmProps> = ({ snackbarText, setSnackbarText }) => {
  const history = useHistory();
  const location = useLocation();

  const { serialNumber } = useParams<{
    serialNumber: string;
  }>();

  const initialTab =
    location.pathname.indexOf('recipes-and-schedules') > -1
      ? Tab.RecipesAndSchedules
      : Tab.Controls;

  const [selectedTab, setSelectedTab] = useState(initialTab);
  const [farm, setFarm] = useState<OrganizationalFarm>();
  const [isSnackbarVisible, setIsSnackbarVisible] = useState(false);
  const [localSnackbarText, setLocalSnackbarText] = useState(snackbarText);

  const pendingMessagesContainer = useRef<Array<string>>([]);
  const timerContainer = useRef<Array<NodeJS.Timeout>>([]);

  const [userActions, setUserActions] = useState<UserAction[]>([]);
  const [maintenanceModePermission, setMaintenanceModePermission] = useState<MaintenancePermission>(
    MaintenancePermission.NONE
  );

  const { error, loading: isOrganizationalFarmLoading } = useQuery(GET_ORGANIZATIONAL_FARM_QUERY, {
    variables: { serialNumber },
    skip: !serialNumber,
    onCompleted: data => {
      const { organizationalFarm } = data;
      setFarm(organizationalFarm);
    }
  });

  useEffect(() => {
    const user = getUser();
    const locationUuid = farm?.location?.locationGroup?.uuid || '';
    const permission: MaintenancePermission = decideMaintenancePermission(locationUuid, user!);
    setMaintenanceModePermission(permission);
  }, [farm, serialNumber]);

  const activateTab = (e: SyntheticEvent) => {
    const target = e.target as HTMLDsTabContainerElement;
    if (target?.nodeName === 'DS-TAB-CONTAINER') {
      const selectedTab = target?.selected;
      const selectedTabName = selectedTab === 0 ? Tab.Controls : Tab.RecipesAndSchedules;
      setSelectedTab(selectedTabName);
      history.push(`/farms/${serialNumber}/${selectedTabName.replace(' & ', '-and-')}/`);
    }
  };

  useEffect(() => {
    if (!!snackbarText) {
      pendingMessagesContainer.current.push(snackbarText);
      toggleSnackbarVisibility(pendingMessagesContainer.current);
      setSnackbarText(undefined);
    }
    return () => {
      // eslint-disable-next-line
      timerContainer.current.forEach(clearTimeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [snackbarText]);

  const showNextMessage = (pendingMessages: string[]) => {
    showSnackbar(pendingMessages[0]);
    deferClose(pendingMessages);
  };

  const showSnackbar = (snackbarText: string) => {
    if (snackbarText) {
      setLocalSnackbarText(snackbarText);
      setIsSnackbarVisible(true);
    }
  };

  const deferClose = (pendingMessages: string[]) => {
    const timer = setTimeout(() => {
      setIsSnackbarVisible(false);
      pendingMessages.shift();
      if (pendingMessages.length > 0) {
        showNextMessage(pendingMessages);
      }
    }, 5000);
    timerContainer.current.push(timer);
  };

  const toggleSnackbarVisibility = (pendingMessages: string[]) => {
    if (pendingMessages.length === 1) {
      showSnackbar(pendingMessages[0]);
      deferClose(pendingMessages);
    }
  };

  const snackbarCallback = (snackbarText: string): void => {
    pendingMessagesContainer.current.push(snackbarText);
    toggleSnackbarVisibility(pendingMessagesContainer.current);
  };

  const { farmState, farmStateError, stopPollingFarmState } = useFarmState(serialNumber, true);
  if (farmStateError) {
    stopPollingFarmState();
  }

  const reloadUserActions = async () => {
    getUserActions(serialNumber).then(setUserActions);
  };

  useEffect(() => {
    reloadUserActions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [snackbarText, serialNumber]);

  // sets object properties on stencil components
  useEffect(() => {
    const breadcrumbs: any = document.querySelector('fsd-breadcrumbs');
    if (breadcrumbs) {
      breadcrumbs.location = {
        ...farm?.location
      };
    }
  }, [farm?.location, maintenanceModePermission]);

  const canMaintenanceMode = maintenanceModePermission !== MaintenancePermission.NONE;
  const selectedTabIndex = selectedTab === Tab.Controls ? 0 : 1;

  return (
    <div>
      {isOrganizationalFarmLoading ? (
        <DsPlaceholder class="farm-header-placeholder"></DsPlaceholder>
      ) : error ? (
        <p>Details could not be loaded for device with serial number {serialNumber} </p>
      ) : (
        <div>
          <FarmHeader>
            <BreadcrumbsContainer>
              <CustomElement
                element="fsd-breadcrumbs"
                props={{
                  serialNumber: farm?.serialNumber
                }}
              />
            </BreadcrumbsContainer>
            <FarmInfo
              farmName={farm?.name!}
              state={farmState?.state!}
              serialNumber={farm?.serialNumber!}
              isOnline={farmState?.isOnline!}
              physicalFarmModel={farm?.physicalFarmModel!}
            />
            {farm?.serialNumber ? (
              <ButtonsContainer>
                <LinkNoDecoration
                  href={formatGrafanaLink(farm.serialNumber)}
                  target="_blank"
                  rel="noreferrer"
                >
                  <DsButton icon="open_in_new" primary={true} label="View Metrics" />
                </LinkNoDecoration>
                <LinkNoDecoration href={`${farmUri(farm.serialNumber)}details`}>
                  <DsButton label="Settings" />
                </LinkNoDecoration>
              </ButtonsContainer>
            ) : null}
          </FarmHeader>
          <DsTabContainer selected={selectedTabIndex} onClick={activateTab}>
            <DsTab selected={selectedTab === Tab.Controls} label="Controls">
              {canMaintenanceMode ? (
                <FarmControls
                  userActions={userActions}
                  reloadUserActions={reloadUserActions}
                  maintenancePermission={maintenanceModePermission}
                  farmState={farmState as FarmStateResponse}
                  serialNumber={serialNumber}
                  farmType={farm?.physicalFarmModel!}
                  snackbarCallback={snackbarCallback}
                ></FarmControls>
              ) : (
                <span>Farm controls are not available to your user</span>
              )}
            </DsTab>
            <DsTab selected={selectedTab === Tab.RecipesAndSchedules} label="Recipes & Schedules">
              <RecipesAndSchedules
                serialNumber={farm?.serialNumber!}
                isOnline={farmState?.isOnline!}
                physicalFarmModel={farm?.physicalFarmModel!}
                userActions={userActions}
                snackbarCallback={snackbarCallback}
              ></RecipesAndSchedules>
            </DsTab>
          </DsTabContainer>
        </div>
      )}
      {isSnackbarVisible && (
        <DsSnackbar
          data-cy="fsd-farm___snackbar"
          labelText={localSnackbarText}
          open={String(isSnackbarVisible)}
        />
      )}
    </div>
  );
};
