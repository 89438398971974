import { PhysicalFarmModel } from '@infarm/faas-middleware-types';
export enum FarmModel {
  Acre = 'ACRE',
  Instorev2 = 'INSTOREV2',
  Instorev17 = 'INSTOREV17'
}

export interface FarmTypeAndModel {
  farmType: PhysicalFarmModel;
  farmVersion?: string;
  svg?: string;
}

export type FarmUUID = string;

export enum MaintenancePermission {
  ENABLE_DISABLE,
  ENABLE_ONLY,
  NONE
}
