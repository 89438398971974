import styled from 'styled-components';
import { FunctionComponent } from 'react';

const FarmListHeaderRow = styled.tr`
  width: 100%;
  display: grid;
  color: #767676;
  grid-template-rows: repeat(1, auto);
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  border-bottom: 1px solid #e7e7e7;
  line-height: 40px;
  font-size: 16px;
  font-weight: 400;
  margin-top: 14px;
`;

interface FarmCellProps {
  span: number;
  leading?: boolean;
  hide?: boolean;
}

interface FarmListHeaderProps {
  hiddenColumns?: string[];
}

const FarmsListHeaderCell = styled.td<FarmCellProps>`
  grid-column: span ${props => props.span} / auto;
  min-height: 40px;
  padding: ${(props: FarmCellProps) => (props.leading ? '0 16px 0 80px' : '0 16px')};
  display: ${(props: FarmCellProps) => (props.hide ? 'none' : 'table-cell')};
`;

export const FarmListHeader: FunctionComponent<FarmListHeaderProps> = ({ hiddenColumns = [] }) => {
  return (
    <FarmListHeaderRow>
      <FarmsListHeaderCell hide={hiddenColumns.includes('name')} leading span={5}>
        Name
      </FarmsListHeaderCell>
      <FarmsListHeaderCell hide={hiddenColumns.includes('id')} span={4}>
        ID
      </FarmsListHeaderCell>
      <FarmsListHeaderCell hide={hiddenColumns.includes('type')} span={1}>
        Type
      </FarmsListHeaderCell>
      <FarmsListHeaderCell hide={hiddenColumns.includes('status')} span={3}>
        Status
      </FarmsListHeaderCell>
      <FarmsListHeaderCell hide={hiddenColumns.includes('connectivity')} span={2}>
        Connectivity
      </FarmsListHeaderCell>
      <FarmsListHeaderCell hide={hiddenColumns.includes('location')} span={7}>
        Location
      </FarmsListHeaderCell>
      <FarmsListHeaderCell hide={hiddenColumns.includes('link')} span={3}></FarmsListHeaderCell>
    </FarmListHeaderRow>
  );
};
