import { DsIcon } from '@infarm/design-system-react/dist/components';
import { FunctionComponent, useState } from 'react';
import styled from 'styled-components';

interface TooltipProps {
  text: string;
  icon: string;
}
const ArrowContainer = styled.div`
  position: relative;
  z-index: 1;
`;

const colour = '#757575';
const Arrow = styled.div`
  position: absolute;
  left: 8px;
  top: 28px;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid ${colour};
`;

const width = 339;
const Tooltip = styled.div`
  position: absolute;
  background-color: ${colour};
  border-radius: 4px;
  width: ${width}px;
  left: -${width / 2 - 24}px;
  top: 32px;
  padding: 4px 8px;
`;

const Text = styled.div`
  color: white;
  font-size: 12px;
  text-align: center;
  margin: 0;
`;

const Container = styled.div`
  height: 24px;
  width: 24px;
`;

export const IconTooltip: FunctionComponent<TooltipProps> = ({ text, icon }) => {
  const [isVisible, setIsVisible] = useState(false);

  const mouseOver = (): void => {
    setIsVisible(true);
  };

  const mouseLeave = (): void => {
    setIsVisible(false);
  };
  return (
    <Container onMouseOver={mouseOver} onMouseLeave={mouseLeave}>
      {isVisible && (
        <ArrowContainer>
          <Arrow />
          <Tooltip>
            <Text>{text}</Text>
          </Tooltip>
        </ArrowContainer>
      )}
      <DsIcon>{icon}</DsIcon>
    </Container>
  );
};
