import { getUser, JwtUser, userHasRole } from '@infarm/auth';
import { DynamicRecipeRowValues } from '../components/dynamic-recipes/DynamicRecipeRow';
import {
  AcrePlantGroupV2,
  DarkIrrigationAcreV2,
  FarmStatus,
  IrrigationAcreV2,
  LightAcreV2,
  Maybe,
  PhysicalFarmModel,
  Schedule
} from '@infarm/faas-middleware-types';
import { MaintenancePermission } from '../pages/types';

export const dayDurationCellLabel = (startDay: number = 0): string => `Day ${startDay + 1}`;

const user: JwtUser = getUser() as JwtUser;

export const userCanEditRecipeName = () =>
  userHasRole('super_admin', user) || userHasRole('crop_scientist', user);

export const userCanEditRecipeValues = () =>
  userCanEditRecipeName() ||
  userHasRole('maintenance_supervisor', user) ||
  userHasRole('maintenance_expert', user);

export const defaultPlantGroupName = 'default';
// 'empty' recipe might come 'hardcoded' from Farmhub and users should not be editing its name
// as defined within this ticket https://infarm.atlassian.net/browse/ST-3924
export const emptyPlantGroupName = 'empty';

export const staticPlantGroupNames = [defaultPlantGroupName, emptyPlantGroupName];

export const isInstoreFarm: (farmType: PhysicalFarmModel) => boolean = (
  farmType: PhysicalFarmModel
) => farmType === PhysicalFarmModel.InStore || farmType === PhysicalFarmModel.InStoreV1;

export const userCanToggleMaintenance = (
  farmIsOnline: boolean,
  maintenanceIsInTransition: boolean,
  userPermissions: MaintenancePermission,
  farmState: FarmStatus
) => {
  return (
    farmIsOnline &&
    !maintenanceIsInTransition &&
    userPermissions !== MaintenancePermission.NONE &&
    ((userPermissions === MaintenancePermission.ENABLE_ONLY && farmState === FarmStatus.Running) ||
      userPermissions === MaintenancePermission.ENABLE_DISABLE)
  );
};

export const stripTypenames = (property: any): any => {
  if (Array.isArray(property)) {
    return property.map(stripTypenames);
  } else if (!!property && typeof property === 'object') {
    const { __typename, ...rest } = property;
    return Object.entries(rest)
      .map(([key, property]) => ({ [key]: stripTypenames(property) }))
      .reduce((acc, current) => {
        return { ...acc, ...current };
      }, {});
  }
  return property;
};

const insertDimmingValue = (
  rows: DynamicRecipeRowValues[],
  index: number,
  intensity: number,
  isStage3: boolean
) => {
  if (isStage3) {
    rows[index] = { ...rows[index], stage3Intensity: intensity };
  } else {
    rows[index] = { ...rows[index], stage2Intensity: intensity };
  }
};

const fillDimmingSection = (
  rowValues: DynamicRecipeRowValues[],
  lightCycles: ReadonlyArray<Maybe<LightAcreV2>>,
  isStage3 = false
) => {
  let elapsedDuration = 0;
  for (const stage2Config of lightCycles) {
    const { cycleDuration, intensity } = stage2Config as any;
    if (cycleDuration) {
      for (let index = elapsedDuration; index < elapsedDuration + cycleDuration; index++) {
        insertDimmingValue(rowValues, index, intensity, isStage3);
      }
    } else {
      for (let index = elapsedDuration; index < rowValues.length; index++) {
        insertDimmingValue(rowValues, index, intensity, isStage3);
      }
    }
    elapsedDuration += cycleDuration;
  }
};

const insertDailyIrrigation = (
  rows: DynamicRecipeRowValues[],
  index: number,
  soakingDurationInSeconds: number,
  schedule: Schedule,
  isFirstIrrigationInCycle: boolean = false
) => {
  let irrigationsPerLightsOn;
  if (schedule?.frequency === 'EVERY_DAY') {
    irrigationsPerLightsOn = schedule?.runsPerDay!;
  } else {
    if (isFirstIrrigationInCycle && schedule?.frequency === 'EVERY_NTH_DAY') {
      irrigationsPerLightsOn = 1;
    }
  }
  rows[index] = {
    ...rows[index],
    soakingTimeLightsOn: soakingDurationInSeconds,
    irrigationsPerLightsOn
  };
};

const shouldIrrigateOnGivenDay = (
  schedule: any,
  indexInIrrigationCycle: number,
  elapsedDuration: number
) =>
  schedule?.frequency === 'EVERY_DAY'
    ? true
    : (indexInIrrigationCycle - elapsedDuration) % schedule!.interval === 0;

const fillDailyIrrigationSection = (
  rowValues: DynamicRecipeRowValues[],
  dailyIrrigation: ReadonlyArray<Maybe<IrrigationAcreV2>>
) => {
  let elapsedDuration = 0;

  for (const { cycleDuration, soakingDurationInSeconds, schedule } of dailyIrrigation as any) {
    if (cycleDuration) {
      for (let index = elapsedDuration; index < elapsedDuration + cycleDuration; index++) {
        const shouldIrrigate = shouldIrrigateOnGivenDay(schedule, index, elapsedDuration);
        insertDailyIrrigation(rowValues, index, soakingDurationInSeconds, schedule, shouldIrrigate);
      }
    } else {
      for (let index = elapsedDuration; index < rowValues.length; index++) {
        const shouldIrrigate = shouldIrrigateOnGivenDay(schedule, index, elapsedDuration);
        insertDailyIrrigation(rowValues, index, soakingDurationInSeconds, schedule, shouldIrrigate);
      }
    }

    elapsedDuration += cycleDuration;
  }
};

const fillNightlyIrrigationSection = (
  rowValues: DynamicRecipeRowValues[],
  nightlyIrrigation?: Maybe<ReadonlyArray<Maybe<DarkIrrigationAcreV2>>>
) => {
  if (!nightlyIrrigation) {
    return;
  }
  let elapsedDuration = 0;

  for (const {
    cycleDuration,
    runsPerNight,
    soakingDurationInSeconds
  } of nightlyIrrigation as any) {
    if (cycleDuration) {
      for (let index = elapsedDuration; index < elapsedDuration + cycleDuration; index++) {
        rowValues[index] = {
          ...rowValues[index],
          irrigationsPerDayLightsOff: runsPerNight,
          soakingTimeLightsOff: soakingDurationInSeconds
        };
      }
    } else {
      for (let index = elapsedDuration; index < rowValues.length; index++) {
        // todo make DRY
        rowValues[index] = {
          ...rowValues[index],
          irrigationsPerDayLightsOff: runsPerNight,
          soakingTimeLightsOff: soakingDurationInSeconds
        };
      }
    }

    elapsedDuration += cycleDuration;
  }
};

export const dynamicRecipeDuration = (recipe: AcrePlantGroupV2) => {
  const dailyDuration = recipe.stage2!.irrigations.reduce(
    (acc, curr) => (curr?.cycleDuration ? acc + curr.cycleDuration : acc),
    0
  );
  const nightlyDuration = recipe.stage2!.darkIrrigations
    ? recipe.stage2!.darkIrrigations?.reduce(
        (acc, curr) => (curr?.cycleDuration ? acc + curr.cycleDuration : acc),
        0
      )
    : 0;
  const stage2Duration = recipe.stage2!.light.reduce(
    (acc, curr) => (curr?.cycleDuration ? acc + curr.cycleDuration : acc),
    0
  );
  const stage3Duration = recipe.stage3!.light.reduce(
    (acc, curr) => (curr?.cycleDuration ? acc + curr.cycleDuration : acc),
    0
  );
  return Math.max(dailyDuration, nightlyDuration, stage2Duration, stage3Duration);
};

export const denormalizeFarmConfiguration: (
  recipe: AcrePlantGroupV2
) => DynamicRecipeRowValues[] = (recipe: AcrePlantGroupV2) => {
  const duration = dynamicRecipeDuration(recipe);
  const rowValues: DynamicRecipeRowValues[] = Array(duration).fill({});

  fillDimmingSection(rowValues, recipe.stage2!.light);
  fillDimmingSection(rowValues, recipe.stage3!.light, true);

  fillDailyIrrigationSection(rowValues, recipe.stage2!.irrigations);
  fillNightlyIrrigationSection(rowValues, recipe.stage2!.darkIrrigations);

  return [...rowValues, { ...constructInfiniteRow(recipe) }];
};

const infiniteConfiguration = (config: any) => config.cycleDuration === null;

export const constructInfiniteRow: (recipe: AcrePlantGroupV2) => DynamicRecipeRowValues = (
  recipe: AcrePlantGroupV2
) => {
  const stage2Light = (recipe.stage2!.light! as LightAcreV2[]).find(infiniteConfiguration);
  const stage3Light = (recipe.stage3!.light! as LightAcreV2[]).find(infiniteConfiguration);
  const irrigation = (recipe.stage2!.irrigations! as IrrigationAcreV2[]).find(
    infiniteConfiguration
  );
  let darkIrrigation;
  if (recipe.stage2!.darkIrrigations) {
    darkIrrigation = (recipe.stage2!.darkIrrigations! as DarkIrrigationAcreV2[]).find(
      infiniteConfiguration
    );
  }

  const row: DynamicRecipeRowValues = {
    day: Number.MAX_SAFE_INTEGER,
    stage2Intensity: stage2Light!.intensity,
    stage3Intensity: stage3Light!.intensity,
    soakingTimeLightsOn: irrigation?.soakingDurationInSeconds
  };
  if (darkIrrigation) {
    row.irrigationsPerDayLightsOff = darkIrrigation.runsPerNight;
    row.soakingTimeLightsOff = darkIrrigation.soakingDurationInSeconds;
  }
  if (irrigation?.schedule?.frequency === 'EVERY_DAY') {
    row.irrigationsPerLightsOn = irrigation?.schedule?.runsPerDay!;
  } else {
    row.irrigateEveryNDays = irrigation?.schedule?.interval!;
  }

  return row;
};
