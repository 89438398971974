import { DsButton } from '@infarm/design-system-react';
import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { ConfirmModalSteps } from './types';

interface IrsModalProps {
  irsStation: number;
  bypassType?: string;
  closeModal: () => void;
  confirmModal?: () => void;
  step: ConfirmModalSteps;
}
const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
  ds-button {
    margin-left: 16px;
  }
`;
export const IrsConfirmModal: FunctionComponent<IrsModalProps> = ({
  irsStation,
  bypassType,
  closeModal,
  confirmModal,
  step
}) => {
  const irsTitleText = irsStation === 1 ? '1 - bottom' : '2 - top';

  let bypassName, mechanismName;

  switch (bypassType) {
    case 'IRRIGATION_STATION':
      bypassName = 'Station Bypass';
      mechanismName = 'Station mechanism';
      break;
    case 'IRRIGATION_STATION_WATER_ONLY':
      bypassName = 'Water Bypass';
      mechanismName = 'Water mechanism';
      break;
    case 'IRRIGATION_STATION_TILT':
      bypassName = 'Tilt Bypass';
      mechanismName = 'Tilt mechanism';
      break;
  }

  return (
    <div>
      {step === ConfirmModalSteps.CONFIRM && (
        <div>
          <h3>
            Are you sure you want to enable {bypassName} {irsTitleText}?
          </h3>
          <p>
            Please be aware that this command will prevent benches from moving to this irrigation
            station.
          </p>
          <p>
            <b>Please do not forget to disable bypass when you are done repairing!</b>
          </p>
        </div>
      )}
      {step === ConfirmModalSteps.POST_CONFIRM && (
        <div>
          <h3>
            {bypassName} for Irrigation Station {irsTitleText} is being enabled.
          </h3>
          <p>This might take a few seconds...</p>
        </div>
      )}
      {step === ConfirmModalSteps.DISABLE && (
        <div>
          <h3>
            Are you sure you want to disable {bypassName} {irsTitleText}?
          </h3>
          <p>Please ensure the {mechanismName} is functional before disabling bypass.</p>
        </div>
      )}
      {step === ConfirmModalSteps.POST_DISABLE && (
        <div>
          <h3>
            {bypassName} for Irrigation Station {irsTitleText} is being disabled.
          </h3>
          <p>This might take a few seconds...</p>
        </div>
      )}
      {(step === ConfirmModalSteps.CONFIRM || step === ConfirmModalSteps.DISABLE) && (
        <ModalFooter>
          <DsButton
            label="Cancel"
            onClick={() => {
              closeModal();
            }}
          />
          <DsButton
            primary
            label="Confirm"
            onClick={() => {
              if (confirmModal) {
                confirmModal();
              }
            }}
          />
        </ModalFooter>
      )}
      {(step === ConfirmModalSteps.POST_CONFIRM || step === ConfirmModalSteps.POST_DISABLE) && (
        <ModalFooter>
          <DsButton
            label="OK"
            onClick={() => {
              closeModal();
            }}
          />
        </ModalFooter>
      )}
    </div>
  );
};
