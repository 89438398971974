import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { DynamicRecipeTable } from '../components/dynamic-recipes/DynamicRecipeTable';
import styled from 'styled-components';
import { DsButton } from '@infarm/design-system-react';
import { DynamicRecipeRowValues } from '../components/dynamic-recipes/DynamicRecipeRow';
import { getMiddlewareApolloClient } from '../lib/apollo-client';
import { denormalizeFarmConfiguration, stripTypenames } from '../lib/utils';
import { GET_ACRE_CONFIG } from '../lib/queries';
import { PerPlantGroupAcreV2Input } from '@infarm/faas-middleware-types';

import { hasDarkIrrigations } from './utils';
import { Butons } from '../components/shared';
// Used to specify which attribute was changed by the user
export enum DynamicRecipeAttribute {
  Stage2Intensity,
  Stage3Intensity,
  IrrigationsPerDayLightsOn,
  SoakingTimeLightsOn,
  IrrigationsPerDayLightsOff,
  SoakingTimeLightsOff,
  CycleDuration,
  IrrigationSchedule
}

// Used to construct data table header
export enum RecipeHeaderCaption {
  Day = 'Days',
  LightStage2 = 'Dimming',
  LightStage3 = 'Dimming',
  Frequency = 'Irrigations',
  IrrigationDay = 'Irrigations',
  SoakingTimeDay = 'Soaking time',
  IrrigationNight = 'Irrigations',
  SoakingTimeNight = 'Soaking time',
  TotalDays = 'Duration',
  RecipeSettings = 'Settings',
  LightRecipe = 'Light recipe',
  IrrigationSchedule = 'Irrigation schedule',
  NightIrrigationsTogggle = 'Night irrigations enabled'
}

const SetPoints = styled.h3`
  margin-top: 1rem;
  font-size: 1.5rem;
  font-weight: 700;
`;

const Header = styled.h2`
  margin-top: 1rem;
`;

const getConfig = async (serialNumber: string) => {
  const client = getMiddlewareApolloClient();
  return await client.query({
    query: GET_ACRE_CONFIG,
    variables: {
      serialNumber
    }
  });
};
export interface DynamicRecipeFormInput {
  recipeName: string;
  recipeSetPoints: DynamicRecipeRowValues[];
}

export const DynamicRecipes: React.FunctionComponent = () => {
  const { recipeName: originalRecipeName, serialNumber } = useParams<{
    serialNumber: string;
    recipeName: string;
  }>();

  const history = useHistory();
  const [recipeRows, setRecipeRows] = useState<DynamicRecipeRowValues[]>([]);
  const [nightlyIrrigationsOn, setNightlyIrrigationsOn] = useState<boolean>(true);

  useEffect(() => {
    (async () => {
      const result = await getConfig(serialNumber);
      const farmConfig = stripTypenames(result.data.farmConfigurationV2);
      const { config } = farmConfig;
      const { perPlantGroup } = config;
      const currentRecipe = perPlantGroup.find(
        (group: PerPlantGroupAcreV2Input) => group.plantGroup === originalRecipeName
      );
      const denormalized = denormalizeFarmConfiguration(currentRecipe);
      setRecipeRows(denormalized);
      setNightlyIrrigationsOn(hasDarkIrrigations(currentRecipe));
    })();
  }, [originalRecipeName, serialNumber]);

  return (
    <>
      <Header>{originalRecipeName}</Header>
      <SetPoints>Recipe set points</SetPoints>
      <DynamicRecipeTable tableValues={recipeRows} nightlyIrrigationsOn={nightlyIrrigationsOn} />
      <Butons>
        <DsButton label="Back" onClick={history.goBack} />
      </Butons>
    </>
  );
};
