import styled from 'styled-components';
import { DynamicRecipeTableHeader, tableBorderColor } from './DynamicRecipeTableHeader';
import { DynamicRecipeRow, DynamicRecipeRowValues } from './DynamicRecipeRow';
import { FunctionComponent } from 'react';

const Table = styled.table`
  border-collapse: collapse;
  border: 1px solid ${tableBorderColor};
  margin: 1rem 0;
`;

export const DynamicRecipeTable: FunctionComponent<{
  tableValues: DynamicRecipeRowValues[];
  nightlyIrrigationsOn: boolean;
}> = ({ tableValues = [], nightlyIrrigationsOn }) => {
  return (
    <Table>
      <DynamicRecipeTableHeader nightlyIrrigationsOn={nightlyIrrigationsOn} />
      <tbody>
        {tableValues.map((rowValues, index) => {
          return (
            <DynamicRecipeRow
              key={`recipe-row-${index}`}
              rowValues={rowValues}
              startDay={index}
              finalRow={tableValues.length - 1 === index}
            />
          );
        })}
      </tbody>
    </Table>
  );
};
