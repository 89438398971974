import { useMutation, useQuery } from '@apollo/client';
import { DsButton, DsPlaceholder, DsRow, DsSnackbar, DsSpacer } from '@infarm/design-system-react';
import { GET_CO2_CONFIG, SET_CO2_CONFIG } from '../../../lib/queries';
import { userCanEditRecipes } from '../../../lib/access-control';
import { useEffect, useState } from 'react';
import { RecipeField } from './RecipeField';
import { RecipeCard, RecipeModal } from './styled-components';

interface AcreCo2RecipeProps {
  serialNumber: string;
  isOnline: boolean;
}

export const AcreCo2RecipeCard = ({ serialNumber, isOnline }: AcreCo2RecipeProps) => {
  const { data, loading, error } = useQuery(GET_CO2_CONFIG, { variables: { serialNumber } });
  const co2Config = data?.physicalFarm?.config?.global?.co2;
  const userCanEditRecipesValue = userCanEditRecipes();

  const [editFormVisible, showEditForm] = useState(false);
  const [success, setSuccess] = useState(false);
  const [isDirty, setDirty] = useState(false);
  const [co2Setpoint, setCo2Setpoint] = useState<number | null>(null);
  const [dosingTime, setDosingTime] = useState<number | null>(null);
  const [settlingTime, setSettlingTime] = useState<number | null>(null);

  const [setCo2Config, mutationResult] = useMutation(SET_CO2_CONFIG, {
    refetchQueries: [GET_CO2_CONFIG]
  });

  useEffect(() => {
    if (mutationResult.called && mutationResult.data) {
      showEditForm(false);
      setSuccess(true);
    }
  }, [mutationResult.called, mutationResult.data]);

  const submitForm = () => {
    mutationResult.reset();
    setCo2Config({
      variables: {
        input: {
          serialNumber,
          co2: {
            setPoint: co2Setpoint,
            dosingTimeInSeconds: dosingTime,
            settlingTimeInSeconds: settlingTime
          }
        }
      }
    });
  };

  useEffect(() => {
    if (!co2Config) return;
    setCo2Setpoint(co2Config.setPoint);
    setDosingTime(co2Config.dosingTimeInSeconds);
    setSettlingTime(co2Config.settlingTimeInSeconds);
    setDirty(false);
    if (editFormVisible) {
      setSuccess(false);
    }
  }, [editFormVisible, co2Config]);

  useEffect(() => {
    if (!co2Config || co2Setpoint === null || settlingTime === null || dosingTime === null) {
      setDirty(false);
      return;
    }
    if (
      co2Setpoint !== co2Config.setPoint ||
      settlingTime !== co2Config.settlingTimeInSeconds ||
      dosingTime !== co2Config.dosingTimeInSeconds
    ) {
      setDirty(true);
    }
  }, [co2Setpoint, settlingTime, dosingTime, co2Config]);

  return (
    <RecipeCard>
      <h3>CO₂ Recipe</h3>
      {error ? (
        <>
          <p>Error loading CO₂ recipe:</p>
          <p>{error.message}</p>
        </>
      ) : loading ? (
        <DsPlaceholder></DsPlaceholder>
      ) : (
        <>
          <table>
            <thead>
              <tr>
                <td>Set point</td>
                <td>Dosing time</td>
                <td>Settling time</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td data-title="Set point">{co2Config.setPoint} ppm</td>
                <td data-title="Dosing time">{co2Config.dosingTimeInSeconds} seconds</td>
                <td data-title="Settling time">{co2Config.settlingTimeInSeconds} seconds</td>
              </tr>
            </tbody>
          </table>
          <DsSpacer></DsSpacer>
          {userCanEditRecipesValue && (
            <>
              <footer>
                <DsButton
                  data-cy={`recipe-card___editCo2Recipe`}
                  label="Edit"
                  onClick={() => showEditForm(true)}
                />
              </footer>
              <RecipeModal
                heading="Edit CO₂ Recipe"
                width={600}
                open={editFormVisible}
                hideCloseButton
                onClosed={() => showEditForm(false)}
              >
                <RecipeField
                  name="Set point"
                  unit="ppm"
                  min={200}
                  max={2000}
                  value={co2Setpoint}
                  onValue={setCo2Setpoint}
                ></RecipeField>
                <RecipeField
                  name="Dosing time"
                  unit="seconds"
                  min={0}
                  max={3600}
                  value={dosingTime}
                  onValue={setDosingTime}
                ></RecipeField>
                <RecipeField
                  name="Settling time"
                  unit="seconds"
                  min={0}
                  max={3600}
                  value={settlingTime}
                  onValue={setSettlingTime}
                ></RecipeField>
                <footer>
                  <DsRow>
                    <DsSpacer></DsSpacer>
                    <DsButton stretch label="Cancel" onClick={() => showEditForm(false)}></DsButton>
                    <DsButton
                      stretch
                      primary
                      label="Apply"
                      disabled={!isDirty || mutationResult.loading}
                      onClick={submitForm}
                    ></DsButton>
                  </DsRow>
                </footer>
                {!isOnline && (
                  <p className="offline">
                    <strong>Farm {serialNumber} is offline:</strong> All changes will be applied
                    when the farm is back online
                  </p>
                )}
              </RecipeModal>
              {mutationResult.error && (
                <DsSnackbar
                  labelText={mutationResult.error.message}
                  open="true"
                  stacked
                ></DsSnackbar>
              )}
              {success && (
                <DsSnackbar
                  labelText="CO₂ recipe has been updated successfully."
                  open="true"
                  stacked
                ></DsSnackbar>
              )}
            </>
          )}
        </>
      )}
    </RecipeCard>
  );
};
