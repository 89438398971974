import {
  FarmConfigurationV2,
  InstorePlantGroupV1,
  InstorePlantGroupV2,
  UserAction
} from '@infarm/faas-middleware-types';
import { FunctionComponent, useEffect, useState } from 'react';
import { DsColumn, DsRow, DsButton } from '@infarm/design-system-react';
import { userCanAddInStoreRecipes } from '../../lib/access-control';
import { CustomElement } from '../CustomElement';
import styled from 'styled-components';
import { ActionKey, setObjectProperties } from './utils';

type InstoreRecipesAndSchedulesProps = {
  isOnline: boolean;
  userActions: UserAction[];
  farmConfig?: FarmConfigurationV2;
};

const PlantRecipesHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const InstoreRecipesAndSchedules: FunctionComponent<InstoreRecipesAndSchedulesProps> = ({
  isOnline,
  userActions,
  farmConfig
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const newRecipeClosedEventName = 'stencilReactBus___newRecipeClosed';

  const removeListeners = () => {
    window.removeEventListener(newRecipeClosedEventName, () => setIsModalOpen(false));
  };

  useEffect(() => {
    if (farmConfig) {
      const stencilElements = [
        {
          elementName: 'instore-climate-container',
          actionKey: ActionKey.InstoreCrac
        },
        {
          elementName: 'instore-tank-refresh-container',
          actionKey: ActionKey.TankRefresh
        }
      ];
      stencilElements.forEach(element => setObjectProperties(element, { farmConfig, userActions }));
      const plantRecipes: any = document.querySelector('plant-recipes');
      if (plantRecipes && !!farmConfig) {
        plantRecipes.userActions = userActions;
        plantRecipes.farmConfig = farmConfig;
      }
    }

    removeListeners();
    window.addEventListener(newRecipeClosedEventName, () => setIsModalOpen(false));

    return () => {
      removeListeners();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [farmConfig, userActions]);
  const maxRecipeSlots = 4;
  return (
    <DsColumn>
      <DsRow>
        <h2>Global recipes</h2>
      </DsRow>
      {farmConfig && (
        <>
          <DsRow>
            <CustomElement
              element={'instore-tank-refresh-container'}
              props={{
                isOnline,
                recipeCardTitle: 'Auto tank refresh'
              }}
            />
            <CustomElement
              element={'instore-climate-container'}
              props={{
                isOnline,
                recipeCardTitle: 'Climate recipe'
              }}
            />
          </DsRow>
          <DsRow>
            <PlantRecipesHeader>
              <h2>Plant recipes</h2>
              {userCanAddInStoreRecipes() && (
                <DsButton
                  label="+ Add a new recipe"
                  onClick={() => setIsModalOpen(true)}
                  disabled={
                    (farmConfig?.config?.perPlantGroup as
                      | InstorePlantGroupV1[]
                      | readonly InstorePlantGroupV2[]).length >= maxRecipeSlots
                  }
                ></DsButton>
              )}
            </PlantRecipesHeader>
          </DsRow>
          <CustomElement
            element={'plant-recipes'}
            props={{
              isOnline,
              isModalOpen
            }}
          />
        </>
      )}
    </DsColumn>
  );
};
