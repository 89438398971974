import { PhysicalFarmModel } from '@infarm/faas-middleware-types';
import styled from 'styled-components';
import { getDisplayFarmTypeAndModel } from '../../pages/utils';
import { CustomElement } from '../CustomElement';

interface FarmInfoProps {
  farmName: string;
  serialNumber: string;
  state: string;
  physicalFarmModel: PhysicalFarmModel;
  isOnline: boolean;
}

const FarmIcon = styled.img`
  pointer-events: all;
  cursor: pointer;
  align-self: center;
  width: 43px;
  height: 48px;
  margin-right: 20px;
  margin-left: 4px;
`;

const InfoContainer = styled.div`
  display: grid;
  grid-template-areas:
    'icon info'
    'icon serial';
  padding: 6px 0;
`;

const TitleRow = styled.div`
  grid-area: info;
  display: flex;
  align-items: center;
`;

const Title = styled.h1`
  margin-right: 16px;
  line-height: 1;
  font-size: 48px;
`;

const IconContainer = styled.div`
  grid-area: icon;
  align-content: center;
`;

const SerialNumberContainer = styled.div`
  grid-area: serial;
  width: 100%;
  color: #767676;
  font-size: 15px;
  line-height: 1.2;
`;

const StatusChipContainer = styled.div`
  margin-right: 16px;
  line-height: 1;
`;

export const FarmInfo = (props: FarmInfoProps) => {
  const { farmName, state, serialNumber, isOnline, physicalFarmModel } = props;
  const { farmType, farmVersion, svg } = getDisplayFarmTypeAndModel(physicalFarmModel);
  const typeVersionString: string = [[farmType, farmVersion].join(' '), serialNumber].join(' · ');
  return (
    <InfoContainer>
      <IconContainer>
        <FarmIcon src={svg} />
      </IconContainer>
      <TitleRow>
        <Title>{farmName}</Title>
        <StatusChipContainer>
          <CustomElement
            element="farm-status-chip"
            props={{
              farmStatus: state
            }}
          />
        </StatusChipContainer>
        <CustomElement
          element="farm-connectivity-icon"
          props={{
            isOnline: isOnline
          }}
        />
      </TitleRow>
      <SerialNumberContainer>{typeVersionString}</SerialNumberContainer>
    </InfoContainer>
  );
};
