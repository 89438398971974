import { DsCard, DsModal } from '@infarm/design-system-react';
import styled from 'styled-components';

export const RecipeCard = styled(DsCard)`
  padding: 24px;

  & > table {
    margin: 16px 0;
    border-collapse: collapse;

    th,
    td {
      padding: 16px 24px;
      text-align: left;
    }
    thead td {
      color: rgb(102, 102, 102);
      padding-bottom: 8px;
    }

    tbody tr:not(:first-child) th,
    tbody tr:not(:first-child) td {
      border-top: 1px solid #f2f2f2;
    }

    @media (max-width: 959px) {
      display: block;
      margin-top: 0;

      tr {
        display: block;
        padding: 16px;
      }

      tr:not(:first-child) {
        border-top: 1px solid #f2f2f2;
      }

      thead,
      thead td {
        display: none;
      }

      tbody {
        display: block;
      }

      tbody th,
      tbody td {
        display: block;
        padding: 0;
        border-top: none !important;
      }

      tbody th::before,
      tbody td::before {
        display: inline-block;
        content: attr(data-title) ':';
        margin-inline-end: 0.5em;
      }
    }
  }

  & > p {
    margin: 16px 0;
    color: rgba(0, 0, 0, 0.6);

    a {
      text-decoration: underline;
    }
  }

  footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-top: 24px;
    border-top: 1px solid #f2f2f2;
  }
`;

export const RecipeModal = styled(DsModal)`
  p {
    margin: 24px 0;
  }

  p.offline {
    text-align: center;
    margin: 24px 0 12px;
    color: #fb8332;
  }

  a {
    text-decoration: underline;
  }
`;
