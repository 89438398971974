import { UserAction, FarmConfigurationV2 } from '@infarm/faas-middleware-types';

interface StencilElement {
  elementName: string;
  actionKey: ActionKey;
}

interface CardProperties {
  userActions: UserAction[];
  farmConfig: FarmConfigurationV2;
}

export enum ActionKey {
  InstoreCrac = 'instore_crac',
  TankRefresh = 'tank_refresh',
  Co2 = 'co2',
  Water = 'water',
  AcreCrac = 'crac'
}

export const getUserActionForStencilElement = (
  cardProperties: CardProperties,
  actionKey: ActionKey
) => {
  const { userActions, farmConfig } = cardProperties;
  return userActions?.find(
    (action: UserAction) => action.section === `${farmConfig?.farmType}.${actionKey}`
  );
};

export const setObjectProperties = (
  stencilElement: StencilElement,
  cardProperties: CardProperties
) => {
  const { elementName, actionKey } = stencilElement;
  const { farmConfig } = cardProperties;
  if (farmConfig) {
    const card: any = document.querySelector(elementName);
    if (card && !!farmConfig) {
      card.userAction = getUserActionForStencilElement(cardProperties, actionKey);
      card.farmConfig = farmConfig;
    }
  }
};

export const formatForCyTag: (elementName: string) => string = (elementName: string) =>
  elementName?.replace(/(^\w|\s\w)/g, m => m.toUpperCase()).replace(/\s/g, '');
