import { getMiddlewareApolloClient } from '../lib/apollo-client';
import { GET_ACRE_CONFIG, GET_ORGANIZATIONAL_FARM_QUERY, GET_USER_ACTIONS } from '../lib/queries';
import {
  AcreConfigV2,
  AcreConfigV2Input,
  FarmConfigurationV2,
  OrganizationalFarm,
  PhysicalFarmModel,
  ScheduleFrequency,
  UserAction,
  OrganizationalFarmModel,
  AcrePlantGroupV2
} from '@infarm/faas-middleware-types';
import { DynamicRecipeRowValues } from '../components/dynamic-recipes/DynamicRecipeRow';

import IconAcre from '../assets/acre-2021.svg';
import IconInstoreV2 from '../assets/instore-v20.svg';
import IconInstoreV17 from '../assets/instore-v17.svg';
import { DocumentNode } from 'graphql';

import { JwtUser, userHasAssignment, userHasRole } from '@infarm/auth';
import { FarmTypeAndModel, MaintenancePermission } from './types';

export const isPlantGroupNameValid: (name: string, existingNames: string[]) => boolean = (
  name: string,
  existingNames: string[]
) => {
  return !!name && !existingNames.includes(name);
};

export const getFarmConfig = async (serialNumber: string): Promise<FarmConfigurationV2> => {
  const client = getMiddlewareApolloClient();
  const farmConfig = await client.query({
    query: GET_ACRE_CONFIG,
    variables: {
      serialNumber
    }
  });
  return farmConfig.data.farmConfigurationV2;
};

export const isUniqueName = (farmConfig: AcreConfigV2 & AcreConfigV2Input, name: string) => {
  const existingNames: string[] = farmConfig?.perPlantGroup?.map(group => group.plantGroup) || [];
  return isPlantGroupNameValid(name, existingNames);
};

export const queryMiddleware = async (query: DocumentNode, variables: any): Promise<any> => {
  const client = getMiddlewareApolloClient();
  return await client.query({
    query,
    variables
  });
};

export const getFarm = async (serialNumber: string): Promise<OrganizationalFarm> => {
  const result = await queryMiddleware(GET_ORGANIZATIONAL_FARM_QUERY, {
    serialNumber
  });
  return result.data.organizationalFarm;
};

export const getUserActions = async (serialNumber: string): Promise<UserAction[]> => {
  const result = await queryMiddleware(GET_USER_ACTIONS, {
    farmId: serialNumber
  });
  return result.data.userActions.actions;
};

const env = (globalThis as any).env || {};

export const formatGrafanaLink = (serialNumber: string | null | undefined): string => {
  if (!serialNumber) {
    return '';
  }
  return `${env.GRAFANA_ENDPOINT}/d/${serialNumber.replace('.', '-')}`;
};

export const farmUri = (serialNumber: string) => `${env.FARMBOARD_ROOT}farms/${serialNumber}/`;

export const getDisplayFarmTypeAndModel = (
  modelString: PhysicalFarmModel | undefined,
  organizationalFarmModel: OrganizationalFarmModel | undefined = undefined
): FarmTypeAndModel => {
  switch (modelString) {
    case PhysicalFarmModel.InStoreV1:
      return {
        farmType: PhysicalFarmModel.InStoreV1,
        farmVersion: 'v 1.7',
        svg: IconInstoreV17
      };
    case PhysicalFarmModel.InStore:
      return {
        farmType: PhysicalFarmModel.InStore,
        farmVersion: 'v 2.0',
        svg: IconInstoreV2
      };
    case PhysicalFarmModel.Acre_1Stage:
    case PhysicalFarmModel.Acre_2Stage:
    case PhysicalFarmModel.Acre_3Stage:
      return {
        farmType: PhysicalFarmModel.Acre_2Stage,
        farmVersion: 'v 1.0',
        svg: IconAcre
      };
    default:
      switch (organizationalFarmModel) {
        case OrganizationalFarmModel.Acre:
          return {
            farmType: PhysicalFarmModel.Acre_2Stage,
            farmVersion: 'v 1.0',
            svg: IconAcre
          };
        case OrganizationalFarmModel.Instore:
          return {
            farmType: PhysicalFarmModel.InStore,
            farmVersion: 'v 2.0',
            svg: IconInstoreV2
          };
        default:
          return {
            farmType: PhysicalFarmModel.NotSet
          };
      }
  }
};

export const maintenanceExpert = 'maintenance_expert';
export const superAdmin = 'super_admin';
export const maintenanceSupervisor = 'maintenance_supervisor';
export const cropScientist = 'crop_scientist';

export const userIsAdmin = (user: JwtUser) => userHasRole(superAdmin, user);

export const decideMaintenancePermission: (
  locationGroupUuid: string,
  user: JwtUser
) => MaintenancePermission = (locationGroupUuid: string, user: JwtUser) => {
  const isSuperAdmin = userIsAdmin(user);

  // Maintenance Experts can enable maintenance mode
  let permission = MaintenancePermission.NONE;
  if (userHasAssignment(locationGroupUuid, maintenanceExpert, user)) {
    permission = MaintenancePermission.ENABLE_ONLY;
  }

  // Maintenance Supervisors and Super Admins can enable and disable maintenance mode
  if (userHasAssignment(locationGroupUuid, maintenanceSupervisor, user) || isSuperAdmin) {
    permission = MaintenancePermission.ENABLE_DISABLE;
  }

  return permission;
};

export const createEmptyRow: () => DynamicRecipeRowValues = () => ({
  day: 1,
  cycleDuration: undefined,
  stage2Intensity: undefined,
  stage3Intensity: undefined,
  soakingTimeLightsOn: undefined,
  irrigationsPerDayLightsOff: undefined,
  soakingTimeLightsOff: undefined,
  irrigationSchedule: {
    type: ScheduleFrequency.EveryDay,
    runsPerDay: 1
  }
});

export const hasDarkIrrigations = (currentRecipe: AcrePlantGroupV2): boolean => {
  const { stage2, stage3 } = currentRecipe;
  const noDarkIrrigations = stage2?.darkIrrigations === null && stage3?.darkIrrigations === null;
  return !noDarkIrrigations;
};
